import React from 'react';
import Div from "@jumbo/shared/Div";
import Link from "@mui/material/Link";
import {ASSET_IMAGES} from "../../utils/constants/paths";
import {useNavigate} from "react-router-dom";

const Logo = ({mini, mode, sx}) => {
    const navigate = useNavigate();

    const handleGoHome = () => {
        navigate("/dashboard")
    }

    return (
        <Div sx={{display: "inline-flex", ...sx}}>
            <Link onClick={handleGoHome}>
                <img src={`${ASSET_IMAGES}/logo-rdy.png`} alt="Rdy logo" width="110" height="35"/>
            </Link>
        </Div>
    );
};

Logo.defaultProps = {
    mode: "light"
};

export default Logo;
