import React, { useEffect } from 'react';
import {Typography} from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import {ASSET_IMAGES} from "../../../../utils/constants/paths";
import {getAssetPath} from "../../../../utils/appHelpers";

const UnderstandingYourResults = () => {
    useEffect(() => {
        document.title = 'Understanding Your Results - Rdy';

        window.scrollTo(0, 0);
    }, [])

    return (
        <React.Fragment>
            <JumboCardQuick
                title={
                    <Typography component={"h2"} variant="h1">
                        Understanding your results
                    </Typography>
                }
                wrapperSx={{pt: 0}}
            >
                <Grid container spacing={3.75}>
                    <Grid item xs={12} md={4}>
                        <CardMedia
                            component="img"
                            sx={{borderRadius: 2}}
                            image={getAssetPath(`${ASSET_IMAGES}/help-center-understanding-results-main.jpg`, "640x820")}
                            alt="Understanding your results"
                        />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Typography variant="body1" color="text.secondary" mb={1}>
                            After you've completed your project analysis, we generate a set of results split between our analysis, our feedback and our suggestions.
                        </Typography>

                        <Typography variant="body1" color="text.secondary" mb={1}>
                            Note that any recommendations provided by Rdy are based on the information you have provided to us. We do not make direct suggestions on what you should do for a particular problem, but instead show you where to focus your efforts to find your answer.
                        </Typography>

                        <Typography variant="body1" color="text.secondary" mb={1}>
                            Within each topic grouping, we provide you an overall confidence score that reflects your level of completeness within any given section.
                        </Typography>

                        <Typography variant="body1" color="text.secondary" mb={1}>
                            For example, if you scored 65% on the solutions costs & benefits section, you have a semi-reasonable grasp on what implementing a solution may result in, but there is still a lot of work left to do before you should move forward. 
                        </Typography>

                        <Typography variant="body1" color="text.secondary" mb={1}>
                            Under each topic is a granular breakdown of each question, the response you provided and our feedback on how to improve, depending on the severity of your answer. 
                        </Typography>

                        <Typography variant="body1" color="text.secondary" mb={1}>
                            As we've provided you the suggestions on how to improve, it is ultimately up to you whether you decide to act on them.
                        </Typography>
                    </Grid>
                </Grid>
            </JumboCardQuick>
        </React.Fragment>
    );
};

export default UnderstandingYourResults;
