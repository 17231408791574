import React from 'react';
import {useNavigate} from 'react-router-dom';
import CardIconText from "@jumbo/shared/CardIconText";

import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';
import Typography from "@mui/material/Typography";

const HelpCenterCard = () => {
    const navigate = useNavigate();

    function handleHelpCenter() {
        navigate('/help-center');
    }

    return (
        <CardIconText
            icon={<HelpOutlineOutlinedIcon fontSize={"large"}/>}
            onHoverIcon={<HelpOutlinedIcon fontSize={"large"}/>}
            color={"#00c4b4"}
            // subTitle={"Help Center"}
            subTitle={<Typography variant="h4">Help Center</Typography>}
            onClick={handleHelpCenter}
            sx={{cursor: 'pointer'}}
            height={90}
        />
    );
};

export default HelpCenterCard;
