import React from 'react';
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import JumboCardFeatured from "@jumbo/components/JumboCardFeatured";
import {ASSET_IMAGES} from "../../../utils/constants/paths";
import {getAssetPath} from "../../../utils/appHelpers";
import {useNavigate} from 'react-router-dom';

const CardRight = ({height}) => {
    const navigate = useNavigate();

    const handleNavigate = () => {
        navigate('/help-center/your-data-your-privacy');
    }

    return (
        <JumboCardFeatured
            showTick
            tickSx={{bgcolor: 'success.main'}}
            direction={"column"}
            textAlign={"center"}
            imgSrc={getAssetPath(`${ASSET_IMAGES}/help-center-data-privacy.png`,"380x175")}
        >
            <Typography variant={"h4"} mb={2}>Your Data, Your Privacy</Typography>
            <Typography mb={3}>Your privacy is important to us. Read more about our policy on how we use the data you provide to Rdy. </Typography>
            <Button variant={"contained"} onClick={handleNavigate}>Learn More</Button>
        </JumboCardFeatured>
    );
};
/* Todo height prop define */
export default CardRight;
