import React from 'react';
import JumboRqList from "@jumbo/components/JumboReactQuery/JumboRqList";
import useChatApp from "../../hooks/useChatApp";
import ConversationItem from "../ConversationItem";
import {chatService} from "../../../../../services/chat-services";

const ConversationsList = () => {
    const {recentConversationsListRef} = useChatApp();
    
    const renderContact = React.useCallback((contact) => {
        return (
            <ConversationItem conversationItem={contact}/>
        );
    }, []);

    return (
        <React.Fragment>
            <JumboRqList
                ref={recentConversationsListRef}
                service={chatService.getRecentConversations}
                renderItem={renderContact}
                primaryKey={"project_id"}
                queryOptions={{
                    queryKey: "recent-conversations-list",
                    dataKey: "conversations"
                }}
                componentElement={"div"}
            />
        </React.Fragment>

    );
};

export default ConversationsList;
