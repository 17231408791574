import React from 'react';
import ProjectsList from "./ProjectsList";
import {Chip, Box, Typography} from "@mui/material";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";

import {ASSET_IMAGES} from "../../../../utils/constants/paths";
import {getAssetPath} from "../../../../utils/appHelpers";

const ActiveProjectsList = ({projects, scrollHeight}) => {
    return (
        <JumboCardQuick
            title={"Active Projects"}
            subheader={<Typography variant="font300" sx={{ color: '#8595A6' }}>Due within the next week</Typography>}
            action={
                <Chip color={"warning"} label={"This week"} size={"small"}/>
            }
            headerSx={{borderBottom: 1, borderBottomColor: 'divider'}}
            wrapperSx={{p: 0, height: 370}}
        >
            <JumboScrollbar
                autoHeight autoHeightMin={scrollHeight ? scrollHeight : 356}
                autoHide
                autoHideDuration={200}
                autoHideTimeout={500}
            >
                {(projects && projects.length > 0) ? (<ProjectsList
                    projects={projects}
                />) : (
                    <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '350px', // Or any desired height
                    }}
                    >
                        <img
                            src={getAssetPath(`${ASSET_IMAGES}/dashboard-active-projects.png`, `715x875`)}
                            alt="Active Projects"
                            style={{
                                opacity: 1,
                                maxWidth: '300px',
                            // maxHeight: '100%',
                            }}
                        />
                    </Box>
                )}
            </JumboScrollbar>
        </JumboCardQuick>
    );
};
/* Todo scrollHeight props define */
export default ActiveProjectsList;
