import React from "react";
import { Navigate } from "react-router-dom";
import rdyRoutes from "./rdyRoutes";
import Login2 from "../pages/auth-pages/login2";
import Signup2 from "../pages/auth-pages/signup2";
import ResetPassword from "../pages/auth-pages/reset-password"
import SetPassword from "../pages/auth-pages/set-password"
import Page from "@jumbo/shared/Page";
import Error404 from "../pages/extra-pages/Error404";

/**
 routes which you want to make accessible to both authenticated and anonymous users
 **/
const routesForPublic = [];

/**
 routes only accessible to authenticated users
 **/
const routesForAuthenticatedOnly = [
    {
        path: "/",
        element: <Navigate to={"/dashboard"} />
    },
    {
        path: "*",
        element: <Error404 />
    }, 
    ...rdyRoutes,
];

/**
 routes only accessible when user is anonymous
 **/
const routesForNotAuthenticatedOnly = [
    {
        path: "/login",
        element: <Page component={Login2} layout={"solo-page"} disableSmLogin={true}/>
    },
    {
        path: "/signup",
        element: <Page component={Signup2} layout={"solo-page"} disableSmLogin={true}/>
    },
    {
        path: "/reset-password",
        element: <Page component={ResetPassword} layout={"solo-page"} disableSmLogin={true}/>
    },
    {
        path: "/set-password",
        element: <Page component={SetPassword} layout={"solo-page"} disableSmLogin={true}/>
    }
];

const routes = [
    ...routesForPublic,
    ...routesForAuthenticatedOnly,
    ...routesForNotAuthenticatedOnly,
];

export {routes as default, routesForPublic, routesForNotAuthenticatedOnly, routesForAuthenticatedOnly};
