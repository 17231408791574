import jwtAxios from "axios";

let baseUrl = 'https://rdy-api.herokuapp.com/api/'
if (process.env.NODE_ENV === 'development') {
    baseUrl = 'http://localhost:4200/api/'
}

const jwtAuthAxios = jwtAxios.create({
    baseURL: baseUrl,
    headers: {
        'Content-Type': 'application/json'
    }
});

jwtAuthAxios.interceptors.response.use(
    res => res,
    err => {
        if(err.response.status === 401) {
            // TODO: Maintain parity with authHelper. Also, improve.
            localStorage.removeItem('token')
            localStorage.removeItem('userId');
            localStorage.removeItem('email');
            localStorage.removeItem('name');
            window.location.replace("/login")
        }
        if(err.response.status === 402) {
            window.location.replace('/settings/plans')
        }
        return Promise.reject(err);
    }
);

export const setAuthToken = (token) => {
    if(token) {
        jwtAuthAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        sessionStorage.setItem('token', token);
    } else {
        delete jwtAuthAxios.defaults.headers.common['Authorization'];
        sessionStorage.removeItem('token');
    }
};

export const getAuthToken = () => {
    return sessionStorage.getItem("token");
};

//todo: define interceptors and other configuration like baseURL, headers etc. here
export default jwtAuthAxios;