import React, { useEffect } from 'react';
import {useNavigate, useParams} from "react-router-dom";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import {Grid, Stack, Button, Typography} from "@mui/material";
import Div from "@jumbo/shared/Div";
import SendIcon from '@mui/icons-material/Send';

const ProjectIntroduction = () => {
    const navigate = useNavigate();
    const { project_id } = useParams();

    useEffect(() => {
        document.title = 'Project Introduction - Rdy';
        window.scrollTo(0, 0);
    }, []);

    const handleStart = (e) => {
        navigate(`/chats/conversation/${project_id}`)
    }

    return (
        <Grid container spacing={3.75}>
            <Grid item xs={6}>
                <JumboCardQuick title={"Before we begin"} wrapperSx={{backgroundColor: 'background.paper', pt: 0}}>
                    <Typography variant="body1" sx={{ mb: 2 }} gutterBottom>
                        You will soon be introduced to our Rdy AI, whose sole purpose is to help you explore your project deeper.
                    </Typography>

                    <Typography variant="body1" sx={{ mb: 2 }} gutterBottom>
                        It's important to be as objective and honest as possible, and to refrain from personal bias. To get the most out of Rdy, please think critically about your current situation.
                    </Typography>

                    <Typography variant="body1" sx={{ mb: 2 }} gutterBottom>
                        The dialogue will progress from examining your problem statement and it's related elements, moving towards your potential solutions and their implications.
                    </Typography>

                    <Typography variant="body1" sx={{ mb: 2 }} gutterBottom>
                        Each question is meant to highlight a possible area of consideration, so understanding the context and being thoughtful in your response is necessary to accurately represent your project.
                    </Typography>

                    <Typography variant="body1" sx={{ mb: 2 }} gutterBottom>
                        It's also okay if you don't have all the answers. We suggest working each Rdy project through to completion to get a valuable perspective of exactly where you are right now. 
                    </Typography>
                </JumboCardQuick>
            </Grid>
            <Grid item xs={6}>
                <JumboCardQuick title={"Rdy Analysis"} wrapperSx={{backgroundColor: 'background.paper', pt: 0}}>
                    <Typography variant="body1" sx={{ mb: 2 }} gutterBottom>
                        Uploading a document will populate our analysis system, providing you our interpretation of your project, which should be read prior to you responding. 
                    </Typography>

                    <Typography variant="body1" sx={{ mb: 2 }} gutterBottom>
                        Our AI will evaluate as much as it can, but note that not all questions will have an analysis. There are some less tangible aspects that only you can answer for us. In cases where our interpretation is incorrect, it's also important that you correct us accordingly.
                    </Typography>
                </JumboCardQuick>
                <Div style={{ marginTop: 20 }} />
                <JumboCardQuick title={"Your Success"} wrapperSx={{backgroundColor: 'background.paper', pt: 0}}>
                    <Typography variant="body1" sx={{ mb: 2 }} gutterBottom>
                        As we know, planning is critical to a project's success. Lack of planning leads to poor decisions, time wasted and unhappy stakeholders when things fail to pan out. Every point we touch on is an opportunity for improvement.
                    </Typography>

                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <Typography variant="body1" sx={{ mb: 2 }} gutterBottom>
                                So let's get started!
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Stack direction="row" spacing={2} style={{ display: "flex", justifyContent: "flex-end" }}>
                                <Button variant="contained" endIcon={<SendIcon/>} onClick={handleStart}>
                                    Start
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </JumboCardQuick>

            </Grid>
        </Grid>
    );
};

export default ProjectIntroduction;