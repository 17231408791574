import React, { useEffect } from 'react';
import {Typography} from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import {ASSET_IMAGES} from "../../../../utils/constants/paths";
import {getAssetPath} from "../../../../utils/appHelpers";
import {useNavigate} from "react-router-dom";

const HowToUseRdy = () => {
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'How to Use Rdy - Rdy';

        window.scrollTo(0, 0);
    }, [])

    const onGoResults = (e) => {
        e.preventDefault()
        navigate('/help-center/understanding-your-results')
    }

    const onGoDocumentAnalysis = (e) => {
        e.preventDefault()
        navigate('/help-center/document-analysis')
    }

    return (
        <React.Fragment>
            <JumboCardQuick
                title={
                    <Typography component={"h2"} variant="h1">
                        How to Use Rdy
                    </Typography>
                }
                wrapperSx={{pt: 0}}
            >
                <Grid container spacing={3.75}>
                    <Grid item xs={12} md={5}>
                        <CardMedia
                            component="img"
                            sx={{borderRadius: 2}}
                            image={getAssetPath(`${ASSET_IMAGES}/help-center-how-to-use-rdy-main.jpg`, "640x820")}
                            alt="How to Use Rdy"
                        />
                    </Grid>
                    <Grid item xs={12} md={7}>
                        
                        <Typography variant="body1" color="text.secondary" mb={1}>
                            Before using Rdy, it's best to keep in mind that we're serving you not as a solution, but as a guide to ensure you're moving in the right direction.
                        </Typography>
                        <Typography variant="h4" color="text.primary">New Project</Typography>
                        <Typography variant="body1" color="text.secondary" mb={1}>
                            When creating a new project, we ask you a few questions to help us better understand what you're working on and what you're trying to achieve. This helps us to tailor the experience to better match what you need for the problem you're trying to solve.
                        </Typography>
                        <Typography variant="body1" color="text.secondary" mb={1}>
                            Before starting, you can upload a document like a Project Requirements Document or similar to help our analysis process, as it helps provide a more detailed analysis. You can read more about it in our <a href="/help-center/document-analysis" onClick={onGoDocumentAnalysis} style={{ color: '#54595e', textDecoration: 'none' }}>Document Analysis</a> article.
                        </Typography>
                        <Typography variant="h4" color="text.primary">The Discussion</Typography>
                        <Typography variant="body1" color="text.secondary" mb={1}>
                            A majority of your interactions with us will revolve around our Chat about your project. We ask you a variety of direct questions to establish not only your understanding of your project, but to highlight areas that may have gotten forgotten.
                        </Typography>
                        <Typography variant="body1" color="text.secondary" mb={1}>
                            With each question, we ask you to provide your confidence level (a sliding scale value) and an optional set of notes that briefly explain why.
                        </Typography>
                        <Typography variant="body1" color="text.secondary" mb={1}>
                            To gain the most out of Rdy, we ask that you leave your ego at the door and remove as many biases as you can while working through your project.
                        </Typography>
                        <Typography variant="h4" color="text.primary">Your Results</Typography>
                        <Typography variant="body1" color="text.secondary" mb={1}>
                            After you've answered all of the questions relevant to your project, your results and suggestions are compiled and displayed to you. Refer to our <a href="/help-center/understanding-your-results" onClick={onGoResults} style={{ textDecoration: 'none', color: '#3B70DB'}}>Understanding your results</a> article for more details.
                        </Typography>
                    </Grid>
                </Grid>
            </JumboCardQuick>
        </React.Fragment>
    );
};

export default HowToUseRdy;
