import React, { useState } from 'react';
import ActiveConversationChat from "./ActiveConversationChat";
import useChatApp from "../../../hooks/useChatApp";

const chatGroupedByDate = (array, key) =>
    Object.entries(
        array.reduce((result, {[key]: k, ...rest}) => {
            (result[k] = result[k] || []).push(rest);
            return result;
        }, {})
    ).map(([sent_date, messages]) => ({
        sent_date,
        messages
    }));
const ConversationChatGroupByDate = ({scrollbarRef}) => {
    const {activeConversation} = useChatApp();
    const [lastMessage, setLastMessage] = useState(null);

    const conversationMessages = React.useMemo(() => {
        if(activeConversation) {
            const conversationMessages = chatGroupedByDate(activeConversation?.messages, 'sent_date');
            setLastMessage(activeConversation.last_message)
            return conversationMessages
        }
        return [];
    }, [activeConversation]);

    return (
        <React.Fragment>
            {
                conversationMessages?.map((messagesGroupByDate, index) => (
                    <ActiveConversationChat
                        key={index}
                        conversation={messagesGroupByDate}
                        lastMessage={lastMessage}
                        scrollbarRef={scrollbarRef}
                    />
                ))
            }
        </React.Fragment>
    );
};

export default ConversationChatGroupByDate;
