import React from 'react';
import {Button, Typography} from "@mui/material";
import Div from "@jumbo/shared/Div";
import {ASSET_IMAGES} from "../../../utils/constants/paths";
import {getAssetPath} from "../../../utils/appHelpers";

import {useNavigate} from "react-router-dom";

const Error404 = () => {
    const navigate = useNavigate();

    const onGoHome = () => {
        navigate('/dashboard')
    }

    return (
        <Div sx={{
            flex: 1,
            flexWrap: 'wrap',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <Div sx={{display: 'inline-flex', mb: 2}}>
                <img src={getAssetPath(`${ASSET_IMAGES}/404.png`)} alt="404" width={480}/>
            </Div>
            <Typography align={"center"} component={"h2"} variant={"h1"} color={"text.primary"} mb={2} sx={{ fontWeight: 300 }}>
                You seem lost...
            </Typography>
            <Button variant="contained" onClick={onGoHome}>Lets go home</Button>
        </Div>
    );
};

export default Error404;
