import React from 'react';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import CardIconText from "@jumbo/shared/CardIconText";

const ResponseCounter = ({ count = "0" }) => {
    return (
        <CardIconText
            icon={<FolderOutlinedIcon fontSize="large"/>}
            title={String(count)}
            height={90}
            subTitle={"Responses"}
            hideArrow={true}
            color={"secondary.main"}
        />
    );
};

export default ResponseCounter;
