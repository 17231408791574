import {Grid, Typography, Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Div from "@jumbo/shared/Div";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    accordion: {
        '&::before': { // This targets the ::before pseudo-element
            content: 'none', // This removes the content
        },
        border: '1px solid #e0e3e8',
        borderRadius: '12px !important',
        marginBottom: '12px'
    }
});

const FrequentlyAskedQuestions = () => {
     const classes = useStyles();

    return (
        <Grid container>
            <Grid xs={1}></Grid>
            <Grid xs={10}>
                <Div sx={{flex: 1}}>
                    <Accordion elevation={0} className={classes.accordion}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography variant="h5" gutterBottom component="div">How are your payments handled?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                            We use Stripe as our payment provider, so your payment details and subscription are handled securely. Your plan details are also readily available through Stripe's self-serve portal, allowing you to view invoices or change your plan any time you want to.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion elevation={0} className={classes.accordion}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography variant="h5" gutterBottom component="div">Can I try Rdy for free?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Yes! Each subscription has a 5-day trial period for all new users, giving you a chance to experience what Rdy has to offer without having to pay anything. No charges will be made until the trial period is over, so you can be worry-free!
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion elevation={0} className={classes.accordion}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel3a-content"
                            id="panel3a-header"
                        >
                            <Typography variant="h5" gutterBottom component="div">Can I cancel at any time?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Yes, you're never locked into a subscription that you don't want. You will be able to manage your subscription through the Stripe self-serve billing portal, which includes changing plans or cancelling completely.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion elevation={0} className={classes.accordion}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel3a-content"
                            id="panel3a-header"
                        >
                            <Typography variant="h5" gutterBottom component="div">How do your plans differ?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Our plans are tailored toward our user's positions in their profession. Our Essential plan provides the most basic functionalities of Rdy, enough that someone with minimal experience would be able to benefit. Our Professional and Advanced plans are geared more towards users in startup or corporate settings, granting the usage of our AI system to help analyze and provide feedback on your project. 
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion elevation={0} className={classes.accordion}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel3a-content"
                            id="panel3a-header"
                        >
                            <Typography variant="h5" gutterBottom component="div">Who can I ask if I have more questions?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                We're always here to answer any questions you may have. Just reach out to us at support@rdyai.com and we'll get back to you as soon as we can!
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Div>
            </Grid>
        </Grid>
    )
}

export default FrequentlyAskedQuestions;