import React from 'react';
import {Typography} from "@mui/material";
import Button from "@mui/material/Button";

import Div from "@jumbo/shared/Div";
import {ASSET_IMAGES} from "../../../utils/constants/paths";
import {getAssetPath} from "../../../utils/appHelpers";

const Empty = ({onNewProject}) => {
    return (
        <Div sx={{
            flex: 1,
            flexWrap: 'wrap',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <Div sx={{display: 'inline-flex', mb: 2}}>
                <img src={getAssetPath(`${ASSET_IMAGES}/empty-projects.png`)} alt="No projects" width={460}/>
            </Div>
            <Typography align={"center"} component={"h2"} variant={"h1"} color={"text.primary"} mb={2} sx={{ fontWeight: 300 }}>
                You have no projects yet...
            </Typography>
            <Button variant="contained" onClick={onNewProject}>Create one!</Button>
        </Div>
    )
};

export default Empty;