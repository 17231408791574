import React, { useContext }  from 'react';
import PlanOutput from '../settingsPlans/PlanOutput';
import FrequentlyAskedQuestions from '../settingsPlans/FrequentlyAskedQuestions';
import OverdueNotice from '../notices/OverdueNotice';
import ReferralNotice from "../notices/ReferralNotice";

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Typography } from "@mui/material";
import Div from "@jumbo/shared/Div";

import { PlanContext } from '../../../contexts/DataContext';

const PlansModal = ({user, activeSubscription, setShowPlansModal, headerText, subheaderText}) => {
    const [open] = React.useState(true);
    const { plans } = useContext(PlanContext);

    const closeModal = () => {
        setShowPlansModal(false)
    }

    const subtextComponent = (
        <DialogContentText sx={{ pt: 1, pb: 3, display: 'block', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
            {(headerText && headerText.length > 0) ? (
                <React.Fragment>
                    <Div><Typography variant="font300" sx={{fontSize: '22px'}}>{headerText}</Typography></Div>
                    {subheaderText && (<Div><Typography variant="font300" sx={{fontSize: '16px'}}>{subheaderText}</Typography></Div>)}
                </React.Fragment>
            ) : (
                <Typography variant="font300" sx={{fontSize: '22px'}}>Upgrade to a paid plan to access everything Rdy has to offer!</Typography>
            )}
        </DialogContentText>
    )

    const overdueComponent = (
        <Div sx={{ mb: 4 }}>
            <OverdueNotice customerPortalLink={user.subscription?.customer_portal_link}/>
        </Div>
    )

    const referralComponent = (
        <Div sx={{ mb: 2 }}>
            <ReferralNotice/>
        </Div>
    )

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'xl'}
            open={open}
            onClose={closeModal}
            scroll={'body'}
        >
            <DialogContent sx={{ pb: 0 }}>
                {user.has_partner && user?.subscription?.max_trial_period === 30 && referralComponent}
                {user?.subscription?.status === 'overdue' ? overdueComponent : subtextComponent}
                <PlanOutput
                    user={user}
                    plans={plans}
                    activeSubscription={activeSubscription}
                />
                <FrequentlyAskedQuestions/>
            </DialogContent>
            <DialogActions sx={{ pt: 0 }}>
                <Button onClick={closeModal}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}

export default PlansModal;