import React from 'react';
import Div from "@jumbo/shared/Div";
import {Typography, IconButton, Tooltip, Paper} from "@mui/material";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

import CircularProgress from '@mui/material/CircularProgress';

const ReceivedMessageContent = ({message, isLoadingResponse = false, isLoadingMessage = false}) => {
    // TODO: Improve 
    if(isLoadingResponse) {
        return (
            <Div
                sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    mb: 2,
                    px: 3,
                    '& .Message-item': {
                        marginTop: '2px'
                    }
                }}
            ></Div>
        )
    } else if (isLoadingMessage) {
        return(<Div
            sx={{
                display: 'flex',
                alignItems: 'flex-start',
                mb: 2,
                px: 3,
                '& .Message-item': {
                    marginTop: '2px'
                }
            }}
        >
            <div style={{ display: 'inline-flex' }}>
                <div className="Message-item">
                    <Paper elevation={0} sx={{p: theme => theme.spacing(1, 2), bgcolor: 'primary.main', color: 'common.white'}}>
                            <Typography variant={"font300"} color={"common.white"} sx={{ display: 'flex' }}>
                                <CircularProgress color="inherit" size={"21px"} />
                            </Typography>
                        </Paper>
                </div>
            </div>
        </Div>)
    } else {
        return(<Div
            sx={{
                display: 'flex',
                alignItems: 'flex-start',
                mb: 2,
                px: 3,
                '& .Message-item': {
                    marginTop: '2px'
                }
            }}
        >
            <div style={{ display: 'inline-flex', maxWidth: '80%' }}>
                <div className="Message-item">
                    <Paper elevation={0} sx={{p: theme => theme.spacing(1, 2), bgcolor: 'primary.main', color: 'common.white'}}>
                        <Typography variant={"font300"} color={"common.white"}>
                            {message?.message}
                        </Typography>
                    </Paper>
                </div>
                {message?.message_tooltip && <div style={{ alignSelf: 'center' }}>
                    <Tooltip 
                    title={
                        <Typography sx={{ fontSize: '12px', fontWeight: '300', color: 'common.white' }}>
                            {message.message_tooltip}
                        </Typography>
                    }>
                        <IconButton sx={{ padding: '4px', ml: '6px' }} >
                            <ErrorOutlineOutlinedIcon sx={{ fontSize: '1rem', color: '#a5a5a5' }}/>
                        </IconButton>
                    </Tooltip>
                </div>}
            </div>
        </Div>)
    }
};

export default ReceivedMessageContent;
