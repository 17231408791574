import React, { useEffect, useState } from 'react';
import {useNavigate} from 'react-router-dom';
import ProjectItem from "./ProjectItem";
import {Typography, Grid} from "@mui/material";
import Button from '@mui/material/Button';
import PlansModal from "../modals/PlansModal"
import Empty from "./Empty"

const ProjectsList = ({user, projects}) => {
    const navigate = useNavigate();

    const [showPlansModal, setShowPlansModal] = useState(false);
    const [activeSubscription, setActiveSubscription] = useState({})
    const [headerText, setHeaderText] = useState(null)
    const [subheaderText, setSubheaderText] = useState(null)

    function handleNewProject() {
        if(activeSubscription.plan_name === "Free" || activeSubscription.status === "overdue") {
            // User must be on a paid plan to create a new project
            setHeaderText(null)
            setSubheaderText(null)
            setShowPlansModal(true)
        } else if (user.statistics.period_project_count >= user.subscription.project_limit) {
            // TODO: This messaging is invalid if they're on the Advanced plan. 
            setHeaderText("You've reached your limit of projects for the month!")
            setSubheaderText("Please upgrade to the next plan to continue")
            setShowPlansModal(true)
        } else {
            navigate('/projects/new');
        }
    }

    useEffect(() => {
        if(user) {
            setActiveSubscription(user.subscription)
        }
    }, [user])

    return (
        <React.Fragment>
            {showPlansModal && <PlansModal
                user={user}
                activeSubscription={activeSubscription}
                setShowPlansModal={setShowPlansModal}
                headerText={headerText}
                subheaderText={subheaderText}
            />}
            <Grid container spacing={0}>
                <Grid item lg={10} md={9} sm={8} xs={7}>
                    <Typography variant={'h2'} mb={3}>Projects</Typography>
                </Grid>
                <Grid item lg={2} md={3} sm={4} xs={5}>
                    <Button fullWidth variant="contained" onClick={handleNewProject}>New Project +</Button>
                </Grid>
            </Grid>

            {(projects && projects.length > 0) ? (
                projects.map((project, key) => (
                    <ProjectItem
                        key={key}
                        projects={projects}
                        item={project}
                        setShowPlansModal={setShowPlansModal}
                    />
                ))
            ) : (
                <Empty
                    onNewProject={handleNewProject}
                />
            )}
        </React.Fragment>
    );
};

export default ProjectsList;
