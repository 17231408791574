import React, { useContext } from 'react';
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import Div from "@jumbo/shared/Div";
import {TextField, Grid, FormControl, InputLabel, Select, Tooltip, MenuItem, Stack, Button} from "@mui/material";

import SendIcon from '@mui/icons-material/Send';

import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import {useTheme} from "@mui/material";

import {useNavigate} from "react-router-dom";
import jwtAuthAxios from "../../../services/auth/jwtAuth";

import { UserContext, ProjectContext } from '../../../contexts/DataContext';


// TODO: If we use the same page for the edit, give them the option to set it as completed
 
const ProjectDetails = () => {
    const navigate = useNavigate();

    const { refreshUserData } = useContext(UserContext)
    const { refreshProjectData } = useContext(ProjectContext)

    // Set date min and max
    const currentDate = new Date()
    const futureDate = new Date();
    futureDate.setFullYear(currentDate.getFullYear() + 2); 

    const currentDateStr = currentDate.toISOString().slice(0, 10);
    const futureDateStr = futureDate.toISOString().slice(0, 10);

    // TODO: Add saved role from settings
    const [teamCount, setTeamCount] = React.useState(" ");
    const [role, setRole] = React.useState(" ");
    const [objective, setObjective] = React.useState(" ");

    const [name, setName] = React.useState(" ");
    const [description, setDescription] = React.useState(" ")
    const [date, setDate] = React.useState(" ");


    const Swal = useSwalWrapper();
    const theme = useTheme();

    const sweetAlerts = (variant, inputText) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: false,
            didOpen: toast => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
        });
        Toast.fire({
            icon: variant,
            title: inputText,
            background: theme.palette.background.paper,
        });
    };

    const formSubmit = (event) => {
        if(name.trim() === "") {
            sweetAlerts('error', 'Please add a project name!');
        } else if (date.trim() === "") {
            sweetAlerts('error', 'Please set a due date!');
        } else if (teamCount.trim() === "") {
            sweetAlerts('error', 'Please select your team count!');
        } else if (role.trim() === "") {
            sweetAlerts('error', 'Please select your role!');
        } else if (objective.trim() === "") {
            sweetAlerts('error', 'Please select your objectives!');
        } else {
            const payload = {
                name: name,
                description: description,
                due_at: date,
                team_count: teamCount,
                role: role,
                objective: objective
            }

            jwtAuthAxios.post("/projects", payload)
                .then(response => {
                    sweetAlerts('success', 'Project created!');
                    refreshUserData()
                    refreshProjectData()
                    navigate(`/projects/${response.data.id}/upload`)

                })
                .catch((error) => {
                    sweetAlerts('error', 'An error occurred while creating a project!');
                })
        }
    }

    return (
        <JumboCardQuick title={"Project Details"} wrapperSx={{backgroundColor: 'background.paper', pt: 1}}>
            <Div sx={{width: '100%'}}>
                <Grid container spacing={3.75}>
                    <Grid item xs={12}>
                        <TextField
                            id="name"
                            label="Project Name"
                            defaultValue=""
                            onChange={(event) => setName(event.target.value)}
                            fullWidth
                            inputProps={{ maxLength: 50 }}
                            
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="outlined-multiline-static"
                            label="Description (Optional)"
                            multiline
                            rows={4}
                            defaultValue=""
                            onChange={(event) => setDescription(event.target.value)}
                            fullWidth
                            inputProps={{ maxLength: 512 }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Tooltip placement="top" title="When does your planning need to be finalized?">
                            <TextField
                                id="date"
                                label="Target Due Date"
                                inputProps={{ min: currentDateStr, max: futureDateStr}}
                                type="date"
                                defaultValue=""
                                onChange={(event) => setDate(event.target.value)}
                                sx={{width: '100%'}}
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6}>
                        <Tooltip placement="top" title="Including yourself, how many people are involved in this project?">
                            <FormControl sx={{width: '100%'}}>
                                <InputLabel id="team-count-select-label">Team Count</InputLabel>
                                <Select
                                    labelId="team-count-select-label"
                                    id="team-count-select"
                                    value={teamCount}
                                    label="Team Count"
                                    onChange={(event) => setTeamCount(event.target.value)}
                                >
                                    <MenuItem disabled value=" ">
                                        <em>How many on the project?</em>
                                    </MenuItem>
                                    <MenuItem value={'eq1'}>1</MenuItem>
                                    <MenuItem value={'lt5'}>2-5</MenuItem>
                                    <MenuItem value={'lt10'}>6-10</MenuItem>
                                    <MenuItem value={'gt10'}>10+</MenuItem>
                                </Select>
                            </FormControl>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6}>
                        <Tooltip placement="top" title="What is your position and level of responsibility on this project?">
                            <FormControl sx={{width: '100%'}}>
                                <InputLabel id="role-select-label">Role</InputLabel>
                                <Select
                                    labelId="role-select-label"
                                    id="role-select"
                                    value={role}
                                    label="Role"
                                    onChange={(event) => setRole(event.target.value)}
                                >
                                    <MenuItem disabled value=" ">
                                        <em>What is your role?</em>
                                    </MenuItem>
                                    <MenuItem value={'individual'}>Individual Contributor</MenuItem>
                                    <MenuItem value={'teamlead'}>Team Lead</MenuItem>
                                    <MenuItem value={'manager'}>Manager</MenuItem>
                                    <MenuItem value={'director'}>Director</MenuItem>
                                    <MenuItem value={'other'}>Other</MenuItem>
                                </Select>
                            </FormControl>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6}>
                        <Tooltip placement="top" title="What are you aiming to accomplish by using Rdy with this project?">
                            <FormControl sx={{width: '100%'}}>
                                <InputLabel id="goal-select-label">Objective</InputLabel>
                                <Select
                                    labelId="goal-select-label"
                                    id="goal-select"
                                    value={objective}
                                    label="Objective"
                                    onChange={(event) => setObjective(event.target.value)}
                                >
                                    <MenuItem disabled value=" ">
                                        <em>What are your goals? </em>
                                    </MenuItem>
                                    <MenuItem value={'clarification'}>Clarification</MenuItem>
                                    <MenuItem value={'concepts'}>Concept development</MenuItem>
                                    <MenuItem value={'decisions'}>Decision making</MenuItem>
                                    <MenuItem value={'solutions'}>Solution ideation</MenuItem>
                                    <MenuItem value={'other'}>Other</MenuItem>
                                </Select>
                            </FormControl>
                        </Tooltip>
                    </Grid>

                    <Grid item xs={12}>
                        <Stack direction="row" spacing={2} style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button variant="contained" endIcon={<SendIcon/>} onClick={formSubmit}>
                                Start
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Div>
        </JumboCardQuick>
    );
};

export default ProjectDetails;
