import React, { useContext, useState, useEffect } from 'react';
import {Grid} from "@mui/material";
import ActiveProjectsList from "./ActiveProjectsList";
import CompletedProjectsList from "./CompletedProjectsList";
import HelpCenterCard from "./HelpCenterCard";
import StartCard from "./StartCard";
import WhatsNew from "./WhatsNew";
import UpgradePlan from "./UpgradePlan";
import PlansModal from "../modals/PlansModal"

import useJumboLayoutSidebar from "@jumbo/hooks/useJumboLayoutSidebar";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";

import {ASSET_IMAGES} from "../../../utils/constants/paths";
import {getAssetPath} from "../../../utils/appHelpers";
import {useNavigate} from 'react-router-dom';

import { UserContext, ProjectContext } from '../../../contexts/DataContext';

const Dashboard = () => {
    const {setSidebarOptions} = useJumboLayoutSidebar();
    const Swal = useSwalWrapper();
    const navigate = useNavigate();
    const { user } = useContext(UserContext);
    const { dashboardProjects } = useContext(ProjectContext)

    const [showPlansModal, setShowPlansModal] = useState(false);
    const [activeSubscription, setActiveSubscription] = useState(user ? user.subscription : {})

    useEffect(() => {
        document.title = 'Dashboard - Rdy';

        setSidebarOptions({
            width: 240,
            minWidth: 80
        })
        window.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(user) {
            setActiveSubscription(user.subscription)

            const hasPartner = user.has_partner
            const planName = user.subscription.plan_name
            const planStatus = user.subscription.status

            // Show the referral notice if the user is on the free plan and has an assigned partner
            // The referralAlertdisplayed value will allow the modal to be shown for the first time, and
            // any subsequent times the user has cleared their cache. 
            if(hasPartner === true && planName === 'Free') {
                setTimeout(referralAlert, 2000);
            }

            // Display the overdue notice if the user has failed to pay for their subscription
            if(planStatus === 'overdue') {
                setTimeout(overdueAlert, 2000)
            }   
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    const referralAlert = () => {
        let displayed = localStorage.getItem('referralAlertDisplayed')

        if(!window.location.pathname.includes('dashboard')){
            return
        }

        if(!displayed) {
            Swal.fire({
                position: 'bottom-start',
                imageUrl: getAssetPath(`${ASSET_IMAGES}/dashboard-clapping-hands.png`),
                imageWidth: 114,
                imageHeight: 150,
                html: '<span style="font-size: 16px">Thanks for checking out Rdy!</span></br></br><span style="font-size: 14px">As a token of our appreciation, we\'re giving you up to <b>50% off</b> AND a <b>30-day free trial</b> on our plans!</span>',
                confirmButtonText: 'Yes please!',
                confirmButtonAriaLabel: 'tell-me-more'
            }).then(result => {
                if(result.value) {
                    navigate('/settings/plans');
                }
            });
            localStorage.setItem('referralAlertDisplayed', true)
        }
    };

    const overdueAlert = () => {
        let displayed = localStorage.getItem('overdueAlertDisplayed')

        if(!displayed) {
            Swal.fire({
                icon: 'warning',
                title: 'Subscription Overdue!',
                html: '<span style="font-weight: 300">A recent payment failed to process due to a card issue.</span><br/><span style="font-weight: 300">Please update your card on Stripe to continue using Rdy!</span>',
                confirmButtonText: 'Update Card',
            }).then(result => {
                if(result.value) {
                    window.open(user.subscription.customer_portal_link, '_blank')
                }
            });
            localStorage.setItem('overdueAlertDisplayed', true)
        }
    };

    return (
        <React.Fragment>
            {showPlansModal && <PlansModal
                user={user}
                activeSubscription={activeSubscription}
                setShowPlansModal={setShowPlansModal}
            />}
            <Grid container spacing={3.75}>
                <Grid item lg={8} md={8} xs={12}>
                    <WhatsNew/>
                </Grid>
                <Grid item lg={4} md={4} xs={12} >
                    <StartCard
                        activeSubscription={activeSubscription}
                        setShowPlansModal={setShowPlansModal}
                    />
                </Grid>

                <Grid item lg={5} md={7} xs={12}>
                    <ActiveProjectsList
                        projects={dashboardProjects.active}
                    />
                </Grid>
                <Grid item lg={4} md={5} xs={12}>
                    <CompletedProjectsList
                        projects={dashboardProjects.completed}
                    />
                </Grid>
                <Grid item lg={3} md={12} xs={12}>
                    <Grid container spacing={5}>
                        <Grid item lg={12} md={6} xs={12}>
                            <HelpCenterCard/>
                        </Grid>

                        <Grid item lg={12} md={6} xs={12}>
                            <UpgradePlan />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default Dashboard;
