import React from 'react';
import {Avatar, Typography} from "@mui/material";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import {ASSET_IMAGES} from "../../../utils/constants/paths";
import {getAssetPath} from "../../../utils/appHelpers";

const WhatsNew = () => {
    return (
        <JumboCardQuick
            title={"What's new with Rdy?"}
            subheader={"The happenings of the past while"}
            avatar={
                <Avatar
                    sx={{width: 72, height: 72}}
                    alt={"Rdy logo"}
                    src={getAssetPath(`${ASSET_IMAGES}/logo-128.png`,"72x72")}
                />
            }
            wrapperSx={{height: '172px', pt: '5px'}}
        >
            <Typography mb={2} variant="body1" sx={{ fontWeight: 300}}>
                If you're seeing this, it means we've just launched! Congrats, and a huge thank you for being one of our early supporters! 
            </Typography>
            <Typography mb={2} sx={{fontWeight: 300}}>
                We've just embarked on our journey to creating an ecosystem to help, support and grow organizations of all sizes! There are a lot of developments in the works with Rdy, so keep an eye on us! And remember, please, give us feedback on how we're doing!
            </Typography>
        </JumboCardQuick>
    );
};

export default WhatsNew;
