import React, { useContext } from 'react';
import {Avatar, ListItemIcon, ListItemText, ListItemButton, ThemeProvider, Typography, Divider, List} from "@mui/material";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LogoutIcon from '@mui/icons-material/Logout';
import {useNavigate} from "react-router-dom";
import JumboDdPopover from "@jumbo/components/JumboDdPopover";
import Div from "@jumbo/shared/Div";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import _ from "lodash";

import {ASSET_IMAGES} from "../../../utils/constants/paths";
import {getAssetPath} from "../../../utils/appHelpers";

import { UserContext } from '../../../contexts/DataContext';

const AuthUserDropdown = () => {
    const navigate = useNavigate();
    const {theme} = useJumboTheme();
    const {setAuthToken, unsetData} = useJumboAuth();
    const { user } = useContext(UserContext);

    const selectProfile = () => {
        navigate("/settings");
    }
    
    const selectHelp = () => {
        navigate("/help-center");
    }

    const selectPlans = () => {
        navigate("/settings/plans")
    }

    const onLogout = () => {
        setAuthToken(null);
        unsetData();
        // TODO: Wipe out all other information
        navigate("/login");
    };

    const avatarIconSrc = getAssetPath(`${ASSET_IMAGES}/avatar-grey-128.png`,"72x72")
    const userEmail = user ? user.email : null
    const userName = user ? user.name : null
 
    return (
        <ThemeProvider theme={theme}>
            <JumboDdPopover
                triggerButton={
                    <Avatar
                        src={avatarIconSrc}
                        sizes={"small"}
                        sx={{boxShadow: 25, cursor: 'pointer'}}
                    />
                }
            >
                <Div sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    p: theme => theme.spacing(2.5),
                    minWidth: '180px',
                    maxWidth: '240px'
                }}>
                    <Avatar src={avatarIconSrc} sx={{width: 60, height: 60, mb: 2}}/>
                    {(userName && userName !== "null")&& <Typography variant={"h5"}>{_.truncate(userName, { length: 22 })}</Typography>}
                    <Typography variant={"body1"} color="text.secondary">{_.truncate(userEmail, { length: 30 })}</Typography>
                </Div>
                <Divider/>
                <nav>
                    <List disablePadding sx={{pb: 1}}>

                        <ListItemButton onClick={selectProfile}>
                            <ListItemIcon sx={{minWidth: 36}}>
                                <PersonOutlineIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Profile" sx={{my: 0}}/>
                        </ListItemButton>

                        <ListItemButton onClick={selectPlans}>
                            <ListItemIcon sx={{minWidth: 36}}>
                                <AccountBalanceWalletOutlinedIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Plans" sx={{my: 0}}/>
                        </ListItemButton>

                        <ListItemButton onClick={selectHelp}>
                            <ListItemIcon sx={{minWidth: 36}}>
                                <HelpOutlineIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Need Help?" sx={{my: 0}}/>
                        </ListItemButton>       

                        <ListItemButton onClick={onLogout}>
                            <ListItemIcon sx={{minWidth: 36}}>
                                <LogoutIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Logout" sx={{my: 0}}/>
                        </ListItemButton>

                    </List>
                </nav>
            </JumboDdPopover>
        </ThemeProvider>
    );
};

export default AuthUserDropdown;
