import React, { useContext, useState, useEffect }  from 'react';
import {useNavigate} from "react-router-dom";
import {Grid, Typography, Tabs, Tab} from "@mui/material";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import Div from "@jumbo/shared/Div";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";

import ReferralNotice from "../notices/ReferralNotice";
import OverdueNotice from "../notices/OverdueNotice";
import PlanOutput from './PlanOutput';
import FrequentlyAskedQuestions from "./FrequentlyAskedQuestions"

import { UserContext, PlanContext } from '../../../contexts/DataContext';

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Div sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Div>
            )}
        </div>
    );
}

const SettingsPlans = () => {
    const navigate = useNavigate();
    const Swal = useSwalWrapper();
    const { plans } = useContext(PlanContext);
    const { user } = useContext(UserContext);

    // Static tab value
    const tabIndex = 1
    // const [plans, setPlans] = useState([]);
    // const [user, setUser] = useState([]);
    const [activeSubscription, setActiveSubscription] = useState(user ? user.subscription : " ");

    const queryString = new URLSearchParams(window.location.search)
    const action = queryString.get('action')
    const plan_id = queryString.get('plan_id')
    const session_id = queryString.get('session_id')

    useEffect(() => {
        document.title = 'Plans - Rdy';
        window.scrollTo(0, 0);
      }, []);

    useEffect(() => {
        if(user) {
            setActiveSubscription(user.subscription)
        }
    }, [user])

    useEffect(() => {
        if(plans) {
            if(plan_id !== undefined && plan_id !== null && action === 'success' && session_id !== undefined) {
                const upgradedPlan = plans.find(plan => plan.id === plan_id)
                handleSuccessModal(upgradedPlan.name)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [plans])

    // Note that this cannot be tested properly locally due to the fact that Stripe doesn't allow localhost
    const handleSuccessModal = (planName) => {
        let subtext = null
        if(planName !== undefined) {
            subtext = <Typography variant="body1" sx={{ mt: '2px' }}>You can now enjoy everything the {planName} plan has to offer!</Typography>
        } else {
            subtext = <Typography variant="body1" sx={{ mt: '2px' }}>Congratulations! You are upgraded and ready to roll!</Typography>
        }

        Swal.fire({
            title: <div class="swal2-icon swal2-info swal2-icon-show" style={{ borderColor: "#a5dc86", color: "#a5dc86", marginTop: '10px'}}><div class="swal2-icon-content" style={{ position: 'relative', top: '-6px', left: '6px'}}>&nbsp;🎉</div></div>,
            html: <div>
                <Typography variant="h6">You're all set!</Typography>
                {subtext}
                </div>,
            confirmButtonText: 'Lets go!'
        });
        // TODO: This appears to work at first glance, but we need to test it heavily in production
        // TODO: Test for double popups
        // http://localhost:3000/settings/plans?action=success&plan_id=d29548b6-2e5a-4109-96bb-3354c1bc3203&session_id=1234
        navigate('/settings/plans')
    };

    const selectProfile = () => {
        navigate("/settings")
    }

    const selectSecurity = () => {
        navigate("/settings/security")
    }

    const overdueComponent = (
        <Div sx={{ mt: 2 }}>
            <OverdueNotice customerPortalLink={user?.subscription?.customer_portal_link}/>
        </Div>
    )

    const referralComponent = (
        <Div sx={{ mt: 2 }}>
            <ReferralNotice/>
        </Div>
    )

    return (
        <Grid container spacing={3.75}>
            <Grid item sm={12}>
                <JumboCardQuick>
                    <Div sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <Tabs value={tabIndex}>
                            <Tab label="Profile" onClick={selectProfile}/>
                            <Tab label="Plans"/>
                            <Tab label="Security" onClick={selectSecurity}/>
                        </Tabs>
                    </Div>

                    {user?.has_partner && user?.subscription?.max_trial_period === 30 && referralComponent}

                    {user?.subscription?.status === 'overdue' && overdueComponent}

                    <TabPanel value={tabIndex} index={1}>
                        <PlanOutput
                            user={user}
                            plans={plans}
                            activeSubscription={activeSubscription}
                        />
                    </TabPanel>
                    <FrequentlyAskedQuestions/>
                </JumboCardQuick>
            </Grid>
        </Grid>
    );
};

export default SettingsPlans;