import React, { useEffect } from 'react';
import ChatAppProvider from "./ChatAppProvider";
import ChatAppLayout from "./ChatAppLayout";

const ChatApp = () => {
    useEffect(() => {
        document.title = 'Chat - Rdy';
        window.scrollTo(0, 0);
      }, []);
      
    return (
        <ChatAppProvider>
            <ChatAppLayout />
        </ChatAppProvider>
    );
};

export default ChatApp;
