import React from 'react';
import Typography from "@mui/material/Typography";
import Div from "@jumbo/shared/Div";

import {ASSET_IMAGES} from "../../../../../app/utils/constants/paths";
import {getAssetPath} from "../../../../../app/utils/appHelpers";

const JumboListNoDataPlaceholder = ({children}) => {
    return (
        <Div
            sx={{
                display: 'flex',
                minWidth: 0,
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                flex: 1,
            }}
        >
            {
                children ? children : (
                    <Div sx={{textAlign: 'center', p: 3, m: 'auto'}}>
                        <Div
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '50vh',
                                width: '100%'
                            }}
                        >
                            <img src={getAssetPath(`${ASSET_IMAGES}/chat-sidebar-empty.png`)} alt="No chats!" width={140}/>
                        </Div> 
                        <Typography align={"center"} component={"h4"} variant={"h4"} color={"text.primary"} mb={2} sx={{ fontWeight: 300 }}>
                                No chats yet...
                            </Typography>
                    </Div>
                )
            }
        </Div>
    );
};

export default JumboListNoDataPlaceholder;