import React from 'react';
import Div from "@jumbo/shared/Div";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import {TabContext, TabPanel} from "@mui/lab";
import ConversationsList from "../ConversationsList";

const ChatAppSidebar = () => {
    const [activeTab, setActiveTab] = React.useState("project");
    return (
        <React.Fragment>
            <TabContext value={activeTab}>
                <Div sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <TabList
                        variant="fullWidth"
                        onChange={(event, newTab) => setActiveTab(newTab)}
                    >
                        <Tab label="Projects" disableRipple value={"project"}/>
                    </TabList>
                </Div>
                <JumboScrollbar
                    style={{minHeight: 200}}
                    autoHide
                    autoHideDuration={200}
                    autoHideTimeout={500}
                    autoHeightMin={30}
                >
                    <TabPanel value={"project"} sx={{p: 0}}>
                        <ConversationsList/>
                    </TabPanel>
                </JumboScrollbar>
            </TabContext>
        </React.Fragment>
    );
};

export default ChatAppSidebar;
