import React from 'react';
import {Typography, Stack} from "@mui/material";
import Div from "@jumbo/shared/Div";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import JumboOverlay from "@jumbo/components/JumboOverlay";
import {ASSET_IMAGES} from "../../../utils/constants/paths";
import {getAssetPath} from "../../../utils/appHelpers";
import {useNavigate} from "react-router-dom";

const MissionCard = () => {
    const navigate = useNavigate();

    const handleGoMission = (event) => {
        navigate("/help-center/our-mission")
    }
    
    return (
        <JumboCardQuick
            bgImage={getAssetPath(`${ASSET_IMAGES}/settings-mission-card.jpg`, "600x800")}
            sx={{height: 200, cursor: 'pointer'}}
            noWrapper
            onClick={handleGoMission}
        >
            <JumboOverlay
                opacity={0.5}
                color={"common.black"}
                margin={['auto', 20, 20, 20]}
                sx={{
                    borderRadius: 2,
                    textAlign: 'center',
                }}
            >
                <Div sx={{p: 3}}>
                    <Stack>
                        
                        <Typography variant={"h2"} color={"common.white"}>Why we do what we do</Typography>
                        <Typography variant={"body1"} color={"common.white"}>
                            We're trying to change the world one project at a time, to save you time, pain and wrong decisions.
                        </Typography>
                        <Typography variant={"caption"} color={"common.white"} sx={{ mt: 1 }}>
                            Read more about our mission
                        </Typography>
                    </Stack>
                </Div>
            </JumboOverlay>
        </JumboCardQuick>
    );
};

export default MissionCard;
