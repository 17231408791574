import React, { useContext } from 'react';
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import {Avatar, Accordion, AccordionDetails, AccordionSummary, Card, LinearProgress, Typography, Chip, Button} from "@mui/material";
import Div from "@jumbo/shared/Div";
import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined';

import {useNavigate} from "react-router-dom";
import { UserContext } from '../../../contexts/DataContext';

const ProjectItem = ({ projects, item, setShowPlansModal }) => {
    const navigate = useNavigate();
    const { user } = useContext(UserContext);

    const [expanded, setExpanded] = React.useState(false);

    const handleClick = (event) => {
        if(event.target.nodeName === "BUTTON") {
            if(user.subscription.status === 'overdue' || user.subscription.plan_name === 'Free') {
                setShowPlansModal(true)
            } else {
                handleRedirect()
            }

        } else if (projects.find((project) => project.id === item.id)) {
            setExpanded(!expanded);
        }       
    }

    const handleRedirect = () => {
        // TODO: Add a project edit page
        // TODO: Add a delete functionality
        if(item.complete) {
            // Redirect to results page
            navigate(`/projects/${item.id}/results`)
        } else {
            // Redirect to the conversation page
            navigate(`/chats/conversation/${item.id}`)
        }
    } 

    let expandIcon = null
    if(item.description && item.description.trim() !== "") {
        if(expanded) {
            expandIcon = <RemoveIcon/>
        } else {
            expandIcon = <AddIcon/>
        }
    }

    return (
        <Card sx={{mb: 1}}>
            <Accordion expanded={expanded} onChange={handleClick} square sx={{borderRadius: 2}}>
                <AccordionSummary
                    expandIcon={expandIcon}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    sx={{
                        px: 3,
                        flexDirection: 'row-reverse',

                        '& .MuiAccordionSummary-content': {
                            alignItems: 'center',

                            '&.Mui-expanded': {
                               margin: '12px 0',
                            }
                        },
                        '.MuiAccordionSummary-expandIconWrapper': {
                            borderRadius: 1,
                            border: 1,
                            color: 'text.secondary',
                            borderColor: 'divider',
                            transform: 'none',
                            height: 28,
                            width: 28,
                            alignItems: 'center',
                            justifyContent: 'center',
                            mr: 1,

                            '&.Mui-expanded': {
                                transform: 'none',
                                color: 'primary.main',
                                borderColor: 'primary.main',
                            },

                            '& svg': {
                                fontSize: '1.25rem',
                            }
                        }
                    }}
                >
                    {expandIcon === null && (
                        <Div sx={{ margin: '6px' }}>
                            <AddIcon sx={{ opacity: '0.01' }}/>
                        </Div>
                    )}
                    <Div sx={{display: {xs: 'none', md: 'block', lg: 'block' }, flexShrink: 0, px: 1}}>
                        <Avatar sx={{width: 52, height: 52}} alt={item.name} src={item.logo} variant="rounded"/>
                    </Div>
                    <Div
                        sx={{
                            width: {xs: 'auto', sm: '20%', lg: '20%'},
                            flexShrink: 0,
                            px: 1,
                            flex: {xs: '1', sm: '0 1 auto', lg: '0 1 auto'},
                        }}
                    >
                        <Typography variant={"h5"} mb={.5} fontSize={14}>{item.name}</Typography>
                        {/* TODO: Consider if the created_at date matters to the user
                        <Typography fontSize={"12px"} variant={"body1"} color={"text.secondary"}>
                            <CalendarTodayOutlinedIcon
                                sx={{
                                    verticalAlign: 'middle',
                                    fontSize: '1rem',
                                    mt: -.25,
                                    mr: 1
                                }}
                            />
                            {item.created_at}
                        </Typography> */}
                    </Div>
                    <Div sx={{
                        width: {xs: 'auto', sm: '20%', md: '20%', lg: '20%'}, 
                        display: {xs: 'none', sm: 'block', md: 'block', lg: 'block'},
                        flexShrink: 0,
                        px: 2}
                        }>
                        <Typography
                                fontSize={"12px"}
                                variant={"h6"}
                                color={"text.secondary"}
                                mb={.25}
                            >
                            Progress
                        </Typography>
                        <LinearProgress
                            variant={"determinate"}
                            color={item.status_chip_color}
                            value={item.progress}
                            sx={{
                                height: 6,
                                borderRadius: 2,
                                backgroundColor: '#E9EEEF'
                            }}
                        />
                    </Div>
                    <Div sx={{
                        display: {xs: 'none', sm: 'block', md: 'block', lg: 'block'},
                        width: {xs: 'auto', sm: '16%', md:'16%', lg: '16%'},
                        flexShrink: 0,
                        px: 1}}>
                        <Typography
                            fontSize={"12px"}
                            variant={"h6"}
                            color={"text.secondary"}
                            mb={.25}
                        >
                            Deadline
                        </Typography>
                        <Typography variant={"body1"}>{item.due_at}</Typography>
                    </Div>
                    <Div sx={{
                        flex: {xs: '0 1 auto', lg: 1},
                        flexShrink: 0,
                        px: 1}}>
                        <Typography
                            fontSize={"12px"}
                            variant={"h6"}
                            color={"text.secondary"}
                            mb={.25}
                            sx={{
                                display: {xs: 'none', sm: 'block', lg: 'block'}
                            }}
                        >
                            Status
                        </Typography>
                        <Chip color={item.status_chip_color} size={"small"} label={item.status}/>
                    </Div>

                    {
                        item.complete ? (
                            <Div sx={{ display: 'flex', justifyContent: 'end', flex: {xs: '0 1 auto', sm: 2, lg: 2}, flexShrink: 0, px: 1}}>
                                <Button endIcon={<ArrowRightOutlinedIcon/>}>
                                    View Results
                                </Button>
                            </Div>
                        ) : (
                            <Div sx={{ display: 'flex', justifyContent: 'end', flex: {xs: '0 1 auto', sm: 2, lg: 2}, flexShrink: 0, px: 1}}>
                                <Button endIcon={<ArrowRightOutlinedIcon/>}>
                                    Continue
                                </Button>
                            </Div>
                        )
                    }
                </AccordionSummary>
                {(item.description && item.description.trim() !== "") && <AccordionDetails sx={{borderTop: 1, borderColor: 'divider', p: theme => theme.spacing(2, 2, 2, 8.25)}}>
                    <Typography variant={"body1"} color={"text.secondary"}>
                        {item.description}
                    </Typography>
                </AccordionDetails>}
                
            </Accordion>
        </Card>
    );
};

export default ProjectItem;
