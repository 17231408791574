import React, {useContext, useEffect, useState } from 'react';
import {useNavigate, useParams} from "react-router-dom";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import Div from "@jumbo/shared/Div";
import {useTheme, Grid, Button, Stack, Typography, ListItem, List} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import PlansModal from "../modals/PlansModal"

import {useDropzone} from "react-dropzone";
import DndWrapper from "./DndWrapper";

import {ASSET_IMAGES} from "../../../utils/constants/paths";
import {getAssetPath} from "../../../utils/appHelpers";
import CircularProgress from '@mui/material/CircularProgress';

import jwtAuthAxios from "../../../services/auth/jwtAuth";

import useJumboLayoutSidebar from "@jumbo/hooks/useJumboLayoutSidebar";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { UserContext } from '../../../contexts/DataContext';

// Validate that they haven't already finished the project
// If they've already uploaded something, redirect them to the conversation
// TODO: Remember to remove the upload option from the dropdown if they've already uploaded once. 

// TODO: Fix the super long title thing
// TODO: Multi-file upload
// TODO: Consider reuploading

const DocumentAnalysis = () => {
    const {setSidebarOptions} = useJumboLayoutSidebar();
    const navigate = useNavigate();

    const Swal = useSwalWrapper();
    const theme = useTheme();
    const { project_id } = useParams();
    const { user } = useContext(UserContext);

    const [isUploading, setIsUploading] = useState(false);
    // const [project, setProject] = useState(null);
    const [acceptedFile, setAcceptedFile] = useState(null);
    const [showPlansModal, setShowPlansModal] = useState(false);
    const [activeSubscription, setActiveSubscription] = useState(user ? user.subscription : {})
    
    useEffect(() => {
        document.title = 'Document Upload - Rdy';

        setSidebarOptions({
            width: 240,
            minWidth: 80
        })
        window.scrollTo(0, 0);

        jwtAuthAxios.get(`/projects/${project_id}`)
            .then(response => {
                if(response.data.complete) {
                    console.log("Project is already complete, redirect to results")
                    navigate(`/projects/${project_id}/results`)
                }
                if(response.data.document_attached) {
                    console.log("Project has already uploaded documents, redirect to chat")
                    navigate(`/chats/conversation/${project_id}`)
                }

                // setProject(response.data)
            })
            .catch(error => console.log(error));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(user) {
            setActiveSubscription(user.subscription)
        }
    }, [user])

    const handleSkip = (event) => {
        navigate(`/projects/${project_id}/introduction`)
    }

    // Alert Related
    const sweetAlerts = (variant, inputText) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: false,
            didOpen: toast => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
        });
        Toast.fire({
            icon: variant,
            title: inputText,
            background: theme.palette.background.paper,
        });
    };

    // File Upload Related
    const onDrop = (acceptedFiles) => {
        setAcceptedFile(acceptedFiles[0]);
    };

    const {acceptedFiles, getRootProps, getInputProps, open} = useDropzone({
        noClick: true,
        onDrop,
        accept: 'application/pdf',
        maxFiles: 1,
        maxSize: (5 * 1024 * 1024)
    });

    const files = acceptedFiles.map(file => (
        <ListItem selected key={file.path} sx={{width: 'auto', mr: 1}}>
            {file.path} - {file.size} bytes
        </ListItem>
    ));

    const handleUpload = async () => {
        if(activeSubscription.plan_name !== "Professional" && activeSubscription.plan_name !== "Advanced") {
            setShowPlansModal(true)
            return;
        }

        if (!acceptedFile) {
            alert('Please select a file to upload');
            return;
        }

        setIsUploading(true)
        const formData = new FormData();
        formData.append('file', acceptedFile);

        jwtAuthAxios.post(`/projects/${project_id}/documents`, formData)
            .then(response => {
                sweetAlerts('success', 'Your file is currently processing!');
                setIsUploading(false)
                navigate(`/projects/${project_id}/introduction`)
            })
            .catch(error => {
                const errorMessage = error.response.data.file[0]
                sweetAlerts('error', `The file ${errorMessage}`)
            })
    }


    return (
        <JumboCardQuick title={"Document Analysis"} wrapperSx={{backgroundColor: 'background.paper', pt: 1}}>
            {showPlansModal && <PlansModal
                user={user}
                activeSubscription={activeSubscription}
                setShowPlansModal={setShowPlansModal}
                headerText={"Document upload is only available on Professional or higher."}
                subheaderText={"Please upgrade to use this feature."}
            />}
            <Grid container spacing={3.75}>
                <Grid item md={6} sm={12} xs={12}>
                    <Typography variant="body1" sx={{ mt: 1 }} gutterBottom>
                        Note: This step is optional, or can be done later.
                    </Typography>
                    <Typography variant="body1" sx={{ mt: 2 }} gutterBottom>
                        Rdy uses AI to interpret and analyze your project-related documents (PRDs, project plans, technical documents) in order to evaluate your current progress. This allows us to identify the most important questions that you should be able to answer in order to move forward on your project.
                    </Typography>
                    <Typography variant="body1" sx={{ mt: 2 }} gutterBottom>
                        Our analysis framework guides you through a self-examination to help you identify major risk factors, unknowns and other shortcomings that could impede your progress, halt the project or otherwise cause considerable setbacks.
                    </Typography>
                    <Typography variant="body1" sx={{ mt: 2 }} gutterBottom>
                        We take the privacy of your documents very seriously. We delete your uploads from our storage immediately after processing and do not store any data of business value within our servers. Please review our <a href="https://www.rdyai.com/terms" target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: '#3B70DB'}}>Terms of Service</a> for more details.
                    </Typography>
                </Grid>

                <Grid item md={6} sm={12} xs={12}>
                    <DndWrapper>
                        <div {...getRootProps()} style={{width: '100%', padding: '32px' }}>
                            <input {...getInputProps()} />

                            <Div sx={{display: "flex", minWidth: 0, justifyContent: 'center', mb: 3}}>
                                <img src={getAssetPath(`${ASSET_IMAGES}/cloud-upload.png`, "200x200")}
                                    width={200} alt={'Document upload'}/>
                            </Div>
                            
                            <Typography variant={"body1"} style={{textAlign: 'center'}}>Drag and drop files here or...</Typography>
                            
                            <Div sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <Button variant="contained" size="small" type="button" onClick={open} sx={{ mt: 2 }}>
                                    Browse files
                                </Button>
                            </Div>
                            <Div sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <Typography variant={"caption"}>PDF only, max 5MB</Typography>
                            </Div>
                            
                        </div>
                    </DndWrapper> 
                    <List disablePadding sx={{display: 'flex'}}>
                        {files}
                    </List>

                    <Grid container>
                        <Grid item xs={files.length > 0 ? 6 : 12} sx={{ mt: 2 }} style={{ paddingTop: '0px' }}>
                            <Stack direction="row" spacing={2} style={{ display: "flex", justifyContent: files.length > 0 ? "flex-start" : "flex-end" }}>
                                <Button variant="contained" endIcon={<CancelScheduleSendIcon/>} onClick={handleSkip}>
                                    Skip
                                </Button>
                            </Stack>
                        </Grid>
                        {files.length > 0 && <Grid item xs={6} sx={{ mt: 2 }} style={{ paddingTop: '0px' }}>
                            {isUploading ? (
                                <Stack direction="row" spacing={2} style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <Button variant="contained" endIcon={<CircularProgress size={20}/>} disabled>
                                        Uploading
                                    </Button>
                                </Stack>
                            ) : (
                                <Stack direction="row" spacing={2} style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <Button variant="contained" endIcon={<SendIcon/>} onClick={handleUpload}>
                                        Upload
                                    </Button>
                                </Stack>
                            )}
                        </Grid>}
                    </Grid>

                    
                </Grid>
                {/* <Grid item xs={6}></Grid> */}
                
            </Grid>
        </JumboCardQuick>
    );
};

export default DocumentAnalysis;
