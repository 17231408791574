import { useState, useEffect } from 'react';

const useFetchData = (authUser, fetchDataFunc, initialData, setData) => {
  const [data, setDataState] = useState(initialData);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if(authUser !== undefined && authUser) {
      const fetchData = async () => {
        setIsLoading(true);
        setError(null);
  
        try {
          const fetchedData = await fetchDataFunc();
          setDataState(fetchedData)
          if (data) {
            setData(fetchedData)
          }
        } catch (error) {
          setError(error);
        } finally {
          setIsLoading(false);
        }
      };
  
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser, fetchDataFunc, setData]);

  return { data, isLoading, error };
};

export default useFetchData;