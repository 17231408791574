import React, { useContext, createContext, useState } from 'react';
import useFetchData from '../hooks/useFetchData';
import { fetchPlanData } from '../services/planService';
import {AuthContext} from "@jumbo/components/JumboAuthProvider/JumboAuthContext";

export const PlanContext = createContext();

export const PlanContextProvider = ({ children }) => {
  const { authUser } = useContext(AuthContext);

  const [plans, setPlans] = useState(null);
  useFetchData(authUser, fetchPlanData, [], setPlans);

  const refreshPlanData = () => {
    fetchPlanData()
        .then((data) => {
            setPlans(data)
        })
  }

  return (
    <PlanContext.Provider value={{ plans, setPlans, refreshPlanData }}>
      {children}
    </PlanContext.Provider>
  );
};