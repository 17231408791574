import React, { useContext, useEffect } from 'react';
import ProjectsList from "./ProjectsList";

import { UserContext, ProjectContext } from '../../../contexts/DataContext';

const Projects = () => {
    const { projects, refreshProjectData } = useContext(ProjectContext);
    const { user } = useContext(UserContext);

    useEffect(() => {
        document.title = 'Projects - Rdy';

        window.scrollTo(0, 0);
        refreshProjectData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
      
    return (
        <React.Fragment>
            <ProjectsList
                user={user}
                projects={projects}
            />
        </React.Fragment>
    );
};

export default Projects;