import React from 'react';
import {Avatar, LinearProgress, ListItemAvatar, ListItemText, Typography} from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import JumboDdMenu from "@jumbo/components/JumboDdMenu";

import {styled} from '@mui/material/styles';
import Badge from '@mui/material/Badge';

const StyledBadge = styled(Badge)(({theme}) => ({
    '& .MuiBadge-badge': {
        right: 0,
        top: 32,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}));

const menuItems = [
    {
        title: "Continue",
        slug: "continue"
    },
    {
        title: "Edit",
        slug: "edit",
    },
    {
        title: "Delete",
        slug: "delete"
    }
];

const ProjectItem = ({project}) => {
    return (
        <React.Fragment>
            <ListItemButton
                component={"li"}
                sx={{
                     p: theme => theme.spacing(1, 3),
                     borderBottom: 1,
                     borderBottomColor: 'divider',

                     '&:last-child': {
                         borderBottomColor: 'transparent'
                     }
                    }}
                >
                <ListItemAvatar>
                    {project.overdue ? (
                        <StyledBadge badgeContent={'D'} color="secondary">
                            <Avatar alt={project.name} src={project.logo}/>
                        </StyledBadge>
                    ) : (
                        <Avatar alt={project.name} src={project.logo}/>
                    )}
                </ListItemAvatar>
                <ListItemText
                    sx={{width: '50%'}}
                    primary={<Typography variant='h5' mb={.5}>{project.name}</Typography>}
                    secondary={<Typography variant={'font300'} fontSize={12} color={'text.secondary'}>{project.due_at}</Typography>}
                />
                <ListItemText sx={{width: '40%', px: 2}}>
                    <LinearProgress variant="determinate" color={"success"} value={project.progress}/>
                </ListItemText>
                <ListItemText>
                    <JumboDdMenu
                    menuItems={menuItems}
                    />
                </ListItemText>
            </ListItemButton>
        </React.Fragment>
    );
};
/* Todo project props */
export default ProjectItem;
