import jwtAuthAxios from "./auth/jwtAuth";

const chatService = {};

// TODO: Improve 
chatService.getRecentConversations = async () => {
    const response = await jwtAuthAxios.get("/projects/conversations");
    const conversations = response.data.map( (data) => {
        return data.data
    })

    return { conversations: conversations };
};

// TODO: Improve
chatService.getConversation = async ({queryKey}) => {
    const {id} = queryKey[queryKey.length - 1];

    let data = null;

    if (id !== undefined) {
        const response = await jwtAuthAxios.get(`/projects/${id}/conversations`);
        data = response.data.data
    }

    return { conversation: data };
};

chatService.addConversationMessage = async (conversationDetail) => {
    await jwtAuthAxios.post(
        `/projects/${conversationDetail.projectId}/conversations/${conversationDetail.conversationId}/responses/${conversationDetail.conversationResponseId}`, 
        {
            content: conversationDetail.content,
            confidence_value: conversationDetail.confidence_value
        }
    );
    
    return;
};

export {chatService};
