import React from 'react';
import Button from "@mui/material/Button";
import {Stack, Typography} from "@mui/material";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import Div from "@jumbo/shared/Div";
import {ASSET_IMAGES} from "../../../utils/constants/paths";
import {getAssetPath} from "../../../utils/appHelpers";
import {useNavigate} from 'react-router-dom';

const UpgradePlan = () => {
    const navigate = useNavigate();

    const handleUpgradeRedirect = () => {
        navigate('/settings/plans');
    }

    return (
        <JumboCardQuick
            sx={{textAlign: 'center', color: 'common.white'}}
            bgColor={["#F25C05", "#F1C94D"]}
            bgGradientDir={"-135deg"}
        >
            <Stack direction={"row"} spacing={1} sx={{justifyContent: 'center', mb: 3}}>
                <Button
                    disableElevation
                    disableRipple
                    variant={"contained"}
                    size={"large"}
                    sx={{
                        bgcolor: '#F5F7FA',
                        color: 'primary.main',
                        '&:hover': {
                            bgcolor: '#F5F5F5'
                        },
                        cursor: 'default'
                    }}
                >Hello Promo!</Button>
            </Stack>
            <Div sx={{display: "flex", minWidth: 0, justifyContent: 'center', mb: 1}}>
                <img src={getAssetPath(`${ASSET_IMAGES}/dashboard-upgrade-sm.png`, "102x102")} alt={"Upgrade now!"}
                     width={102}/>
            </Div>
            <Typography
                variant={"h5"}
                color={"common.white"}
                sx={{mb: '2px', fontWeight: 300}}
            >Up to 50% off all plans for a limited time!</Typography>
            <Button variant={"contained"} color={"primary"} sx={{mb: .5, mt: 2}} onClick={handleUpgradeRedirect}>Upgrade now!</Button>
        </JumboCardQuick>
    );
};

export default UpgradePlan;
