import jwtAuthAxios from "./auth/jwtAuth";
import Cookies from 'js-cookie';

const authServices = {};

authServices.getCurrentUser = async () => {
    const {data} = await jwtAuthAxios.get("/users");
    return data;
};

authServices.updateUser = async (formData) => {
    const {data} = await jwtAuthAxios.put('/users', 
    {
        data: formData
    });
    return data;
}

//loginCreds must be {email: "abc@example.com", password: "ABC123DEF"}
authServices.signIn = async (loginCreds) => {
    const {data} = await jwtAuthAxios.post('/users/login', 
    {
        user: loginCreds
    });
    return data;
};

authServices.signUp = async (signupCreds) => {
    const partnerCookieName = 'referral_id'
    const partnerCookieValue = Cookies.get(partnerCookieName)
    
    const {data} = await jwtAuthAxios.post('users',
    {
        user: signupCreds,
        partner_id: partnerCookieValue
    })

    return data;
};

export default authServices;