import React from 'react';
import {ListItem, ListItemIcon, ListItemText} from "@mui/material";
import List from "@mui/material/List";

import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import HistoryEduOutlinedIcon from '@mui/icons-material/HistoryEduOutlined';
import FlipOutlinedIcon from '@mui/icons-material/FlipOutlined';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import TextFieldsOutlinedIcon from '@mui/icons-material/TextFieldsOutlined';

const PlanFeatures = ({activeSubscription, trialDays, features}) => {
    let trialComponent = null
    if(trialDays > 0 && activeSubscription.plan_name === 'Free') {
        trialComponent = 
            <ListItem>
                <ListItemIcon sx={{minWidth: 32, color: 'inherit'}}>
                    <LocalOfferOutlinedIcon fontSize={"small"}/>
                </ListItemIcon>
                <ListItemText primary={`${trialDays}-Day Free Trial`}/>
            </ListItem>
    }

    let characterLimitText = "No Uploads"
    if(features.character_limit > 0) {
        const val = (features.character_limit / 1000).toFixed(0) + 'k';
        characterLimitText = `${val} Character Limit on Uploads`
    }

    return (
        <List disablePadding sx={{mb: 4}}>
            {trialComponent}
            <ListItem>
                <ListItemIcon sx={{minWidth: 32, color: 'inherit'}}>
                    <HistoryEduOutlinedIcon fontSize={"small"}/>
                </ListItemIcon>
                <ListItemText primary={`${features.creations} Project Creations/Mo`}/>
            </ListItem>
            <ListItem>
                <ListItemIcon sx={{minWidth: 32, color: 'inherit'}}>
                    <FlipOutlinedIcon fontSize={"small"}/>
                </ListItemIcon>
                {features.analysis ? (
                    <ListItemText primary="AI Project Analysis"/>
                ) : (
                    <ListItemText primary="Basic Project Analysis"/>
                )}
            </ListItem>
            <ListItem>
                <ListItemIcon sx={{minWidth: 32, color: 'inherit'}}>
                    <TextFieldsOutlinedIcon fontSize={"small"}/>
                </ListItemIcon>
                <ListItemText primary={characterLimitText}/>
            </ListItem>
            <ListItem>
                <ListItemIcon sx={{minWidth: 32, color: 'inherit'}}>
                    <ForumOutlinedIcon fontSize={"small"}/>
                </ListItemIcon>
                <ListItemText primary={features.premium_support ? "Premium Support" : "Basic Support"}/>
            </ListItem>
        </List>
    );
};

export default PlanFeatures;
