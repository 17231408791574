import React, { useEffect } from 'react';
import {Grid} from "@mui/material";
import CardLeft from "./CardLeft";
import CardMid from "./CardMid";
import CardRight from "./CardRight";
// import Contact from "./Contact";
import Articles from "./Articles";

const HelpCenter = () => {
    useEffect(() => {
        document.title = 'Help Center - Rdy';
        window.scrollTo(0, 0);
      }, []);

    return (
        <Grid container spacing={3.75}>
            <Grid item xs={12} md={6} lg={4}>
                <CardLeft/>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <CardMid/>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <CardRight/>
            </Grid>
            <Grid item xs={12}>
                <Articles/>
            </Grid>
            {/* <Grid item xs={12}>
                <Contact/>
            </Grid> */}
        </Grid>
    );
};

export default HelpCenter;