import React, { useEffect } from 'react';
import {Typography} from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import {ASSET_IMAGES} from "../../../../utils/constants/paths";
import {getAssetPath} from "../../../../utils/appHelpers";

const YourDataYourPrivacy = () => {

    useEffect(() => {
        document.title = 'Your Data, Your Privacy - Rdy';

        window.scrollTo(0, 0);
    }, [])

    return (
        <React.Fragment>
            <JumboCardQuick
                title={
                    <Typography component={"h2"} variant="h1">
                        Your Data, Your Privacy
                    </Typography>
                }
                wrapperSx={{pt: 0}}
            >
                <Grid container spacing={3.75}>
                    <Grid item xs={12} md={7}>
                        
                        <Typography variant="body1" color="text.secondary" mb={1}>
                           We understand how important handling your data is, so below is a simple and easy to understand explanation of how we use it. We understand the trust necessary for us to collaborate together effectively.
                        </Typography>

                        <Typography variant="h4" color="text.primary">
                            Your Personal Data
                        </Typography>

                        <Typography variant="body1" color="text.secondary" mb={1}>
                            Your personal data is a collection of basic information about you (name, email) as well in part your professional role (organization, position, etc.). This data is stored securely within our database and will not be used by us for any purpose outside of Rdy, and outside of providing our agreed upon services to you.
                        </Typography>

                        <Typography variant="h4" color="text.primary">
                            Your Transactional Data
                        </Typography>

                        <Typography variant="body1" color="text.secondary" mb={1}>
                            Your transactional data, which includes any project documentation or specifications that are uploaded to be used by the Rdy system, have only a temporary life within our hands. Once your files are processed, they are subsequently removed from our system.
                        </Typography>

                        <Typography variant="body1" color="text.secondary" mb={1}>
                            We do fully understand the risks on both your and our end of storing critical and important business documentation, and thus we delete them from our system after they have been processed, or after 60 minutes in case of any processing error.
                        </Typography>

                        <Typography variant="body1" color="text.secondary" mb={1}>
                            This means that no project related documents are stored on our system for more than 60 minutes, unless agreed upon by you.
                        </Typography>

                        <Typography variant="h4" color="text.primary">
                            Data Usage
                        </Typography>

                        <Typography variant="body1" color="text.secondary" mb={1}>
                            Under no circumstances will the data you upload to the Rdy site be used for personal or organizational gain outside the scope of providing services through Rdy. 
                        </Typography>

                        <Typography variant="h4" color="text.primary">
                            Sharing Data
                        </Typography>

                        <Typography variant="body1" color="text.secondary" mb={1}>
                            We do not sell any of your data to any third-party companies. We do actively use the following third-party services, so in accordance with our Terms of Service, by using Rdy, you acknowledge that your data will be made available to:
                            <ul>
                                <li>Google Analytics - Platform analytics</li>
                                <li>SendGrid - Email management</li>
                                <li>Stripe - Payment handling</li>
                                <li>OpenAI - AI processing</li>
                            </ul>
                        </Typography>

                        <Typography variant="body1" color="text.secondary" mb={1}>
                            For further information, please refer to our <a href="https://www.rdyai.com/privacy-policy" target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: '#3B70DB'}}>Privacy Policy</a> and <a href="https://www.rdyai.com/terms" target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: '#3B70DB'}}>Terms of Service</a>. 
                        </Typography>

                    </Grid>
                    <Grid item xs={12} md={5}>
                        <CardMedia
                            component="img"
                            sx={{borderRadius: 2}}
                            image={getAssetPath(`${ASSET_IMAGES}/help-center-data-privacy-main.jpg`, "640x820")}
                            alt="Your Data, Your Privacy"
                        />
                    </Grid>
                </Grid>
            </JumboCardQuick>
        </React.Fragment>
    );
};

export default YourDataYourPrivacy;
