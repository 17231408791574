import React, { useContext, useEffect, useState } from 'react';
import {useNavigate} from 'react-router-dom';
import {Typography, Button} from "@mui/material";
import Div from "@jumbo/shared/Div";
import {ASSET_IMAGES} from "../../../../../../utils/constants/paths";
import {getAssetPath} from "../../../../../../utils/appHelpers";
import PlansModal from "../../../../modals/PlansModal"

import { UserContext } from '../../../../../../contexts/DataContext';

// Empty chat page
const ContentPlaceholder = () => {
    const navigate = useNavigate();
    const { user } = useContext(UserContext);
    const [showPlansModal, setShowPlansModal] = useState(false);
    const [activeSubscription, setActiveSubscription] = useState({})

    function onNewProject() {
        if(activeSubscription.plan_name === "Free" || activeSubscription.status === "overdue") {
            // User must be on a paid plan to create a new project
            setShowPlansModal(true)
        } else {
            navigate('/projects/new');
        }
    }

    useEffect(() => {
        if(user) {
            setActiveSubscription(user.subscription)
        }
    }, [user])

    return (
        <Div
            sx={{
                textAlign: 'center',
                margin: 'auto',
                p: 3
            }}
        >
            {showPlansModal && <PlansModal
                user={user}
                activeSubscription={activeSubscription}
                setShowPlansModal={setShowPlansModal}
            />}
            <img src={getAssetPath(`${ASSET_IMAGES}/chat-empty.png`)} alt="Start a project and let's chat!" width={400}/>
            <Typography variant={"h2"} mt={2} color={"text.primary"} sx={{ fontWeight: 300 }}>Start a project and let's chat!</Typography>
            <Button variant="contained" onClick={onNewProject} sx={{ mt: 2 }}>New Project!</Button>
        </Div>
    );
};

export default ContentPlaceholder;
