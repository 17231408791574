import React, { useEffect }  from 'react';
import {Typography} from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import {ASSET_IMAGES} from "../../../../utils/constants/paths";
import {getAssetPath} from "../../../../utils/appHelpers";

const WhatKindOfDocumentsDoesRdyUse = () => {
    useEffect(() => {
        document.title = 'What kind of documents does Rdy use? - Rdy';

        window.scrollTo(0, 0);
    }, [])

    return (
        <React.Fragment>
            <JumboCardQuick
                title={
                    <Typography component={"h2"} variant="h1">
                        What kind of documents does Rdy use?
                    </Typography>
                }
                wrapperSx={{pt: 0}}
            >
                <Grid container spacing={3.75}>
                    <Grid item xs={12} md={4}>
                        <CardMedia
                            component="img"
                            sx={{borderRadius: 2}}
                            image={getAssetPath(`${ASSET_IMAGES}/help-center-technology-main.jpg`, "640x820")}
                            alt="What kind of documents does Rdy use?"
                        />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Typography variant="h4" color="text.primary">Document Styles</Typography>
                        <Typography variant="body1" color="text.secondary" mb={1}>
                            We built Rdy to be flexible enough that it accepts and interprets any style of documentation, as long as it has the appropriate information contained within.
                        </Typography>
                        <Typography variant="body1" color="text.secondary" mb={1}>
                            Initially Rdy AI was trained on a varying style of PRDs (Product Requirements Document), design documents and technical specifications. We had originally built Rdy primarily for Product and Engineering roles, but have since, and continue to, expand our capabilities towards more generalized applications. 
                        </Typography>
                        <Typography variant="body1" color="text.secondary" mb={1}>
                            The fundamental basis for how Rdy operates is applicable to any department in an organization, and relies mostly on the presence of a problem, its solutions and a dissection of each. Therefore, there isn't any one singular style of document that we require. 
                        </Typography>
                        <Typography variant="h4" color="text.primary">Format & Length</Typography>
                        <Typography variant="body1" color="text.secondary" mb={1}>
                            At the moment, Rdy only accepts PDFs with character lengths being dictated by the plan you're subcribed to. Over time, as we improve our algorithms and optimize our approach, we intend on being more flexible in our file acceptance criteria. 
                        </Typography>
                    </Grid>
                </Grid>
            </JumboCardQuick>
        </React.Fragment>
    );
};

export default WhatKindOfDocumentsDoesRdyUse;
