import React from 'react';
import { UserContextProvider } from '../contexts/UserContext';
import { PlanContextProvider } from '../contexts/PlanContext';
import { ProjectContextProvider } from '../contexts/ProjectContext';

const DataProvider = ({ children }) => {
  return (
    <UserContextProvider>
      <PlanContextProvider>
        <ProjectContextProvider>
          {children}
        </ProjectContextProvider>
      </PlanContextProvider>
    </UserContextProvider>
  );
};

export default DataProvider;