import React from 'react';
import {Bar, BarChart, ResponsiveContainer, Tooltip} from 'recharts';
import Div from "@jumbo/shared/Div";
import {capitalizeFLetter} from "@jumbo/utils";

// export const salesHistoryWithPast = [
//     {name: '1', title: 'Problem Origin', percentage: 10},
//     {name: '2', title: 'Event & Cause', percentage: 20},
//     {name: '3', title: 'Consequences', percentage: 30},
//     {name: '4', title: 'People (Problem)', percentage: 40},
//     {name: '5', title: 'Solution Origin', percentage: 50},
//     {name: '6', title: 'Costs & Benefits', percentage: 60},
//     {name: '7', title: 'People (Solution)', percentage: 70},
//     {name: '8', title: 'Success Metrics', percentage: 80},
//     {name: '9', title: 'Preferred Solution', percentage: 100},
//     // {name: 'O', title: '123', current: 1300, past: 3000, something: 100},
//     // {name: 'N', title: '123', current: 1500, past: 800, something: 100},
//     // {name: 'D', title: '123', current: 2000, past: 600, something: 1000},
// ];


const ReportChart = ({chartData}) => {

    return (
        <ResponsiveContainer height={100}>
            <BarChart data={chartData}>
                <Tooltip
                    animationEasing={"ease-in-out"}
                    content={({active, label, payload}) => {
                        return active ? (
                            <Div sx={{color: "common.white"}}>
                                <div style={{
                                    color: '#f4912e',
                                    fontSize: 8,
                                    letterSpacing: 2,
                                    textTransform: 'uppercase'
                                }}>
                                    Title
                                </div>
                                <div style={{
                                    color: '#f4912e'
                                }}>
                                    {chartData.find((element) => String(element.name) === String(label)).title}
                                </div>
                                {payload.map((row, index) => {
                                    return (
                                        <div key={index} className={index !== payload.length - 1 ? "mb-1" : ""}>
                                            <div style={{
                                                color: row.color,
                                                fontSize: 8,
                                                letterSpacing: 2,
                                                textTransform: 'uppercase'
                                            }}>
                                                {capitalizeFLetter(row.name)}
                                            </div>
                                            <div style={{
                                                color: row.color
                                            }}
                                            >{row.value}%
                                            </div>
                                        </div>
                                    )
                                })}
                            </Div>
                        ) : null;
                    }}
                    wrapperStyle={{
                        background: 'rgba(255,255,255,0.9)',
                        borderRadius: 4,
                        padding: '5px 8px',
                        fontWeight: 500,
                        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'
                    }}
                />
                {/* <XAxis dataKey="name" tickLine={false} axisLine={false}/> */}

                <Bar dataKey="score" fill="#3b70db" stackId={"a"} maxBarSize={10} barSize={4}/>
            </BarChart>
        </ResponsiveContainer>
    );
};

export default ReportChart;
