import React, { useContext, createContext, useState } from 'react';
import useFetchData from '../hooks/useFetchData';
import { fetchUserData, updateUserData } from '../services/userService';
import {AuthContext} from "@jumbo/components/JumboAuthProvider/JumboAuthContext";

export const UserContext = createContext();

export const UserContextProvider = ({ children }) => {
  const { authUser } = useContext(AuthContext);

  const [user, setUser] = useState(null);
  useFetchData(authUser, fetchUserData, [], setUser);

  const refreshUserData = () => {
    fetchUserData()
        .then((data) => {
            setUser(data)
        })
  }

  return (
    <UserContext.Provider value={{ user, setUser, refreshUserData, updateUserData }}>
      {children}
    </UserContext.Provider>
  );
};