import React from 'react';
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import {Card, CardContent, ListItem, Typography} from "@mui/material";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Button from "@mui/material/Button";
import Div from "@jumbo/shared/Div";
import {ASSET_IMAGES} from "../../../utils/constants/paths";
import {getAssetPath} from "../../../utils/appHelpers";
import {useNavigate} from "react-router-dom";

// Could have this current plan, then an upsell to the next plan. 

const CurrentPlan = ({activePlan}) => {
    const navigate = useNavigate();
    const handleUpgrade = (event) => {
        // TODO: Send directly to Stripe
        navigate("/settings/plans")
    }

    let isAdvanced = false
    if(activePlan === 'Advanced') {
        isAdvanced = true
    }

    let headerText = null
    let featuresArray = []
    let upgradeText = null

    switch(activePlan) {
        case 'Essential':
            headerText = <Typography component={"div"} variant={"body1"}><Typography component={"span"} variant={"h1"}>$19</Typography>/mo - Essential Plan</Typography>
            featuresArray.push("10 Project Creations/mo")
            featuresArray.push("Basic Project Analysis")
            upgradeText = "Upgrade to Professional for AI document analysis!"
            break;
        case 'Professional':
            headerText = <Typography component={"div"} variant={"body1"}><Typography component={"span"} variant={"h1"}>$39</Typography>/mo - Professional Plan</Typography>
            featuresArray.push("25 Project Creations/mo")
            featuresArray.push("AI Project Analysis")
            upgradeText = "Upgrade to Advanced for larger document uploads!"
            break;
        case 'Advanced':
            headerText = <Typography component={"div"} variant={"body1"}><Typography component={"span"} variant={"h1"}>$99</Typography>/mo - Advanced Plan</Typography>
            featuresArray.push("100 Project Creations/mo")
            featuresArray.push("AI Project Analysis")
            break;
        default:
            headerText = <Typography component={"div"} variant={"h3"}>Free Plan</Typography>
            featuresArray.push("No Project Creations")
            featuresArray.push("Viewer access")
            upgradeText = "Upgrade to the Essential Plan to get started!"
            break;
    }

    return (
        <Card>
            <CardHeader title="Your current plan" sx={{ paddingBottom: '12px'}}/>
            <Divider/>
            <CardContent
                sx={{
                    pt: 2
                }}>
                <Stack direction={"row"} spacing={2}>
                    <Grid item xs={8}>
                        {headerText}
                        <List>
                            {featuresArray.map((item) => {
                                return (
                                    <ListItem disablePadding sx={{mb: '5px'}}><ArrowForwardIosIcon
                                sx={{fontSize: 14, mr: 1}}/><Typography variant="font300">{item}</Typography></ListItem>
                                )
                            })}
                        </List>
                        {!isAdvanced && (
                            <React.Fragment>
                                <Divider variant="middle"/>
                                <Typography
                                    component={"div"}
                                    variant={"body1"}
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        textAlign: 'center',
                                        marginTop: '16px',
                                        paddingRight: '20px'
                                    }}
                                >
                                    {upgradeText}
                                </Typography>
                            </React.Fragment>
                        )}
                    </Grid>
                    <Grid item xs={4}>
                        <Div sx={{textAlign: 'center'}}>
                            <img src={getAssetPath(`${ASSET_IMAGES}/settings-profile-plan.png`, '82x82')}  alt={''} width={"120"} style={{ opacity: '1', marginBottom: '0px' }}/>
                            {!isAdvanced && <Button variant={"contained"} onClick={handleUpgrade}>Upgrade!</Button>}
                        </Div>
                    </Grid>
                </Stack>
            </CardContent>
        </Card>
    );
};

export default CurrentPlan;
