import React from 'react';
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import {Grid, Typography} from "@mui/material";

const Instructions = () => {
    return (
        <Grid container>
            <JumboCardQuick title={"Getting Started"} wrapperSx={{backgroundColor: 'background.paper', pt: 0}}>
                <Typography variant="body1" sx={{ mt: 1 }} gutterBottom>
                    For every project exists a fundamental "problem" and it's set of possible solutions. Each Rdy project will rely on this concept, regardless of the scale of the issue. 
                </Typography>
                <Typography variant="body1" sx={{ mt: 2 }} gutterBottom>
                    Designed for the technology sector, Rdy's methodologies can be applied across various industries, projects, and even your personal life. We support projects at any stage, from rough ideas to execution, guiding you confidently towards completion.
                </Typography>
                <Typography variant="body1" sx={{ mt: 2 }} gutterBottom>
                    While working through your project, we'll ask you questions about the core problem and your approach to solving it, so maintaining the right mindset is critical. 
                </Typography>
                <Typography variant="h4" sx={{ mt: 2 }} gutterBottom>
                    Before we begin
                </Typography>
                <Typography variant="body1" sx={{ mt: 2 }} gutterBottom>
                    Please fill in a few preliminary questions to help us understand the scope of the project and the problem that you're facing. 
                </Typography>
            </JumboCardQuick>
        </Grid>
    );
};

export default Instructions;