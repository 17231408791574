import React, { useContext, createContext, useState } from 'react';
import useFetchData from '../hooks/useFetchData';
import { fetchProjectsData, fetchActiveProjectData, fetchCompletedProjectData } from '../services/projectService';
import {AuthContext} from "@jumbo/components/JumboAuthProvider/JumboAuthContext";

export const ProjectContext = createContext();

export const ProjectContextProvider = ({ children }) => {
  const { authUser } = useContext(AuthContext);

  const [projects, setProjects] = useState(null);
  const [activeProjects, setActiveProjects] = useState(null)
  const [completedProjects, setCompletedProjects] = useState(null)

  useFetchData(authUser, fetchProjectsData, [], setProjects);
  useFetchData(authUser, fetchActiveProjectData, [], setActiveProjects);
  useFetchData(authUser, fetchCompletedProjectData, [], setCompletedProjects);

  const refreshProjectData = () => {
    fetchProjectsData()
        .then((data) => {
            setProjects(data)
        })
  }
  
  const dashboardProjects = {
    active: activeProjects,
    completed: completedProjects
  }

  return (
    <ProjectContext.Provider value={{ projects, setProjects, refreshProjectData, dashboardProjects }}>
      {children}
    </ProjectContext.Provider>
  );
};