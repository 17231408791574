import React, { useState } from 'react';
import {Form, Formik} from "formik";
import Div from "@jumbo/shared/Div";
import * as yup from "yup";
import JumboAppTextField from "@jumbo/components/JumboAppTextField";
import LoadingButton from "@mui/lab/LoadingButton";

import {useNavigate} from "react-router-dom";
import authServices from "../../../../services/auth-services";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";

import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import {useTheme} from "@mui/material";

const validationSchema = yup.object({
    email: yup
        .string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required')
        .min(6, 'Email is too short')
        .max(64, 'Email is too long'),
    password: yup
        .string('Enter your password')
        .required('Password is required')
        .min(8, 'Password is too short')
        .max(64, 'Password is too long')
});

const SignupForm = () => {
    const queryString = new URLSearchParams(window.location.search)
    const {setAuthToken} = useJumboAuth();
    const navigate = useNavigate();
    const Swal = useSwalWrapper();
    const theme = useTheme();
    
    const [loading, setLoading] = useState(false);
    // const [defaultEmail, setDefaultEmail] = useState(queryString.get('email'));
    const defaultEmail = queryString.get('email');

    const sweetAlerts = (variant, inputText) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: false,
            didOpen: toast => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
        });
        Toast.fire({
            icon: variant,
            title: inputText,
            background: theme.palette.background.paper,
        });
    };

    const onSignup = (name, email, password) => {
        if (loading === true) {
            return
        }
        authServices.signUp({email, password})
            .then((data) => {
                sweetAlerts('success', 'Signup successful!');
                setAuthToken(data?.token);
                setLoading(false);
                navigate("/dashboard");  
            })
            .catch((error) => {
                if(error.response?.data?.errors?.email) {
                    sweetAlerts('error', 'Email already taken!');
                } else {
                    sweetAlerts('error', 'Something went wrong!');
                }
                setLoading(false);
                
            });
    };

    return (
        <Formik
            validateOnChange={true}
            initialValues={{
                email: defaultEmail || ''
            }}
            validationSchema={validationSchema}
            onSubmit={(data, {setSubmitting}) => {
                setSubmitting(true);
                setLoading(true);
                onSignup(data.name, data.email, data.password);
                setSubmitting(false);
            }}
        >
            {({isSubmitting}) => (
                <Form noValidate autoComplete="off">
                    <Div sx={{mb: 1, mt: 1}}>
                        <JumboAppTextField
                            fullWidth
                            name="email"
                            label="Email"
                        />
                    </Div>
                    <Div sx={{mb: 2, mt: 1}}>
                        <JumboAppTextField
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                        />
                    </Div>
                    <LoadingButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        size="medium"
                        sx={{mb: 3}}
                        loading={isSubmitting}
                    >Sign Up</LoadingButton>
                </Form>
            )}
        </Formik>
    );
};

export default SignupForm;
