import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

const exportReducers = history => {
    return combineReducers({
        router: connectRouter(history),
    });
};

export default exportReducers;

