import React from 'react';
import {Card, CardContent, Typography} from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import {ASSET_IMAGES} from "../../../utils/constants/paths";
import {useNavigate} from 'react-router-dom';

const CardMid = () => {
    const navigate = useNavigate();

    const handleNavigate = () => {
        navigate('/help-center/our-mission');
    }

    return (
        <Card>
            <CardContent sx={{textAlign: 'center', color: 'common.white', bgcolor: 'warning.main', padding: 0, height: '212px'}}>
                <CardMedia
                    component="img"
                    height="212"
                    image={`${ASSET_IMAGES}/help-center-our-mission.jpg`}
                    alt="Our Mission"
                />
            </CardContent>
            <CardContent sx={{textAlign: 'center'}}>
                <Typography variant={"h4"}>Our Mission</Typography>
                <Typography
                    variant={"body1"}
                    color={"text.secondary"}
                    fontSize={"14px"} mb={2}
                >Our vision starts with making the world a more productive and constructive place to live in.</Typography>
                <Button size={"medium"} disableElevation variant={"contained"}
                     onClick={handleNavigate}>Tell me more</Button>
            </CardContent>
        </Card>
    );
};

export default CardMid;
