import React from 'react';
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import JumboRqList from "@jumbo/components/JumboReactQuery/JumboRqList";
import ArticleItem from "./ArticleItem";
// import {cryptoNewsService} from "../../../services/cryptonews-services";
import {helpCenterService} from "../../../services/helpcenter-services";

const Articles = () => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const renderArticleItem = React.useCallback((news) => {
        return <ArticleItem item={news}/>
    });

    // TODO: Rip this out, it's unnecessary
    const [queryOptions] = React.useState({
        queryKey: "article",
        dataKey: "helpCenter"
    });
    return (
        <JumboCardQuick
            headerSx={{
                borderBottom: 1,
                borderBottomColor: 'divider',
                '& .MuiCardHeader-action': {
                    my: -.75
                }
            }}
            wrapperSx={{
                p: 0,
                '&:last-child': {
                    pb: 2
                },
                '& .MuiCollapse-entered:last-child': {
                    '& .MuiListItemButton-root': {
                        borderBottom: 0,
                        borderBottomColor: 'transparent',
                    }
                }
            }}
        >
            <JumboScrollbar
                autoHeight
                autoHeightMin={365}
                autoHide
                autoHideDuration={200}
                autoHideTimeout={500}
            >
                <JumboRqList
                    queryOptions={queryOptions}
                    primaryKey={"id"}
                    service={helpCenterService.getNews}
                    primary={"id"}
                    renderItem={renderArticleItem}
                    componentElement={"div"}
                    wrapperSx={{pb: 1}}
                />
            </JumboScrollbar>
        </JumboCardQuick>
    );
};

export default Articles;
