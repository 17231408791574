import React, { useContext, useEffect } from 'react';
import {Grid} from "@mui/material";
import ProjectDetails from "./ProjectDetails";
import Instructions from "./Instructions";
import {useNavigate} from 'react-router-dom';

import { UserContext } from '../../../contexts/DataContext';

const ProjectNew = () => {
    const navigate = useNavigate();
    const { user } = useContext(UserContext);

    useEffect(() => {
        document.title = 'New Project - Rdy';
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if(user) {
            const planName = user.subscription.plan_name
            const planStatus = user.subscription.status

            if(planName === 'Free' || planStatus === 'overdue') {
                // User should not be here if they're on the free plan
                // For now, we will shoot them back to the dashboard
                console.log("User is not on a paid plan")
                navigate('/dashboard')
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    return (
        <Grid container spacing={3.75}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
                <ProjectDetails/>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
                <Instructions/>
            </Grid>
        </Grid>
    );
};

export default ProjectNew;