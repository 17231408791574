import React, { useContext, useEffect } from 'react';
import {Grid, Typography, Tabs, Tab, TextField, Box, FormControl, InputLabel, Select, MenuItem, Button} from "@mui/material";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
 
import Div from "@jumbo/shared/Div";
import CurrentPlan from "./CurrentPlan";
import ProjectCounter from "./ProjectCounter";
import ResponseCounter from "./ResponseCounter";
import MissionCard from "./MissionCard"

import countryList from 'react-select-country-list';
import {useNavigate} from "react-router-dom";

import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import {useTheme} from "@mui/material";

import { UserContext } from '../../../contexts/DataContext';

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Div sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Div>
            )}
        </div>
    );
}

const SettingsProfile = () => {
    const navigate = useNavigate();
    const { user, refreshUserData, updateUserData } = useContext(UserContext);

    useEffect(() => {
        window.scrollTo(0, 0);
        document.title = 'Settings - Rdy';
      }, []);
    
    const tabIndex = 0

    const [name, setName] = React.useState(user ? user.name : " ");
    const [email, setEmail] = React.useState(user ? user.email : " ");
    const [organization, setOrganization] = React.useState(user ? user.setting.organization : " ");
    const [country, setCountry] = React.useState(user ? user.setting.country : " ");
    const [division, setDivision] = React.useState(user ? user.setting.division : " ");
    const [experience, setExperience] = React.useState(user ? user.setting.experience : " ");
    const [projectCount, setProjectCount] = React.useState(user ? user.statistics.project_count : " ");
    const [responseCount, setResponseCount] = React.useState(user ? user.statistics.response_count : " ");

    const countryOptions = countryList().getData();

    const Swal = useSwalWrapper();
    const theme = useTheme();

    const selectPlans = () => {
        navigate("/settings/plans")
    }

    const selectSecurity = () => {
        navigate("/settings/security")
    }

    const sweetAlerts = (variant, inputText) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: false,
            didOpen: toast => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
        });
        Toast.fire({
            icon: variant,
            title: inputText,
            background: theme.palette.background.paper,
        });
    };

    useEffect(() => {
        if (user) {
            setName(user.name)
            setEmail(user.email)
            setOrganization(user.setting.organization)
            setCountry(user.setting.country)
            setDivision(user.setting.division)
            setExperience(user.setting.experience)
            setProjectCount(user.statistics.project_count)
            setResponseCount(user.statistics.response_count)
        }
    }, [user])
        
    const handleCountryChange = (selectedCountry) => {
        setCountry(selectedCountry);
    }

    const formSubmit = () => {
        const payload = {
            user: {
                name: name,
                setting: {
                    organization: organization,
                    country: country,
                    division: division,
                    experience: experience
                }
            }
        }

        updateUserData(payload)
            .then(response => {
                refreshUserData();
                sweetAlerts('success', 'Settings saved!');
            })
            .catch(error => {
                sweetAlerts('error', 'An error occurred while saving!');
            })
    }
    
    const countryMenuItems = countryOptions.map((country, index) => (
        <MenuItem key={"country-" + index} value={country.value}>{country.label}</MenuItem>
    ));

    return (
        <Grid container spacing={3.75}>
            <Grid item lg={7} md={12} sm={12}>
                <JumboCardQuick>
                    <Div sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <Tabs value={tabIndex}>
                            <Tab label="Profile"/>
                            <Tab label="Plans" onClick={selectPlans}/> 
                            <Tab label="Security" onClick={selectSecurity}/>
                        </Tabs>
                    </Div>
                    <TabPanel value={tabIndex} index={0}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': {m: 1, width: '92%'},
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            {/* <Button variant="contained" onClick={refreshUser}>Refresh</Button>
                            <Button variant="contained" onClick={outputUser}>Output</Button> */}
                            <Div>
                                <Typography variant={"h3"}>Personal</Typography>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="name"
                                            label="Name"
                                            value={name}
                                            onChange={(event) => setName(event.target.value)}
                                            inputProps={{ maxLength: 40 }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            disabled
                                            id="email"
                                            label="Email *"
                                            value={email}
                                        />
                                    </Grid>
                                </Grid>
                            </Div>

                            <Div>
                                <Typography variant={"h3"} sx={{ mt: 1 }}>Professional</Typography>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="organization"
                                            label="Organization"
                                            value={organization}
                                            onChange={(event) => setOrganization(event.target.value)}
                                            inputProps={{ maxLength: 30 }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl sx={{ m: 1, minWidth: '92%' }}>
                                            <InputLabel id="country-select-label">Country</InputLabel>
                                            <Select
                                                id="country-select"
                                                label="Country"
                                                value={country}
                                                onChange={(event) => handleCountryChange(event.target.value)}
                                            >
                                                {countryMenuItems}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl sx={{ m: 1, minWidth: '84%' }}>
                                            <InputLabel id="division-select-label">Division</InputLabel>
                                            <Select
                                                id="division-select"
                                                value={division}
                                                label="Division"
                                                defaultValue=" "
                                                onChange={(event) => setDivision(event.target.value)}
                                            >
                                                <MenuItem value={'engineering'}>Engineering</MenuItem>
                                                <MenuItem value={'product'}>Product Management</MenuItem>
                                                <MenuItem value={'marketing'}>Marketing & Sales</MenuItem>
                                                <MenuItem value={'customersuccess'}>Customer Success/Support</MenuItem>
                                                <MenuItem value={'operations'}>Operations</MenuItem>
                                                <MenuItem value={'humanresources'}>Human Resources</MenuItem>
                                                <MenuItem value={'finance'}>Finance</MenuItem>
                                                <MenuItem value={'executive'}>Executive</MenuItem>
                                                <MenuItem value={'other'}>Other</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl sx={{ m: 1, minWidth: '84%' }}>
                                            <InputLabel id="experience-select-label">Experience</InputLabel>
                                            <Select
                                                id="experience-select"
                                                value={experience}
                                                label="Experience"
                                                defaultValue=" "
                                                onChange={(event) => setExperience(event.target.value)}
                                            >
                                                <MenuItem value={'lt1'}> &lt; 1 year</MenuItem>
                                                <MenuItem value={'lt3'}>1-3 years</MenuItem>
                                                <MenuItem value={'lt5'}>3-5 years</MenuItem>
                                                <MenuItem value={'lt10'}>5-10 years</MenuItem>
                                                <MenuItem value={'gt10'}>10+ years</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Div>
                        </Box>
                        
                        <Div style={{ marginTop: "16px", display: "flex", justifyContent: "flex-end" }}>
                            <Button variant="contained" onClick={formSubmit}>Save</Button>
                        </Div>
                    </TabPanel>
                </JumboCardQuick>
            </Grid>
            <Grid item lg={5} md={12} sm={12}>
                {/* I'm not sure if this a good thing or a bad thing.
                It could be a detractor if they realize they're not using it */}
                <Grid item xs={12} sx={{mb: 3}}>
                    <Grid container spacing={3.75}>
                        <Grid item md={6} xs={12}>
                            <ProjectCounter count={projectCount} />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <ResponseCounter count={responseCount} />
                        </Grid>
                        <Grid item lg={12} md={6} xs={12}>
                            <CurrentPlan
                                activePlan={user?.subscription?.plan_name}
                            />
                        </Grid>
                        <Grid item lg={12} md={6} xs={12}>
                            <MissionCard />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default SettingsProfile;