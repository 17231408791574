import React from "react";
import {Grid, Button} from "@mui/material";
import PlanCards from "./PlanCards";
import PlanFeatures from "./PlanFeatures";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import {useTheme} from "@mui/material";

const PlanOutput = ({user, plans, activeSubscription}) => {
    const Swal = useSwalWrapper();
    const theme = useTheme();

    const handleError = variant => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: toast => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
        });

        Toast.fire({
            icon: 'error',
            title: 'An error occurred, please contact support!',
            background: theme.palette.background.paper,
        });
    };

    const handleSelectPlan = (event) => {
        const planId = event.currentTarget.getAttribute('planid')
        const plan = plans.find((item) => item.id === planId);

        if(!plan.payment_link) {
            return handleError()
        } 

        const userId = localStorage.getItem('userId')
        const userEmail = localStorage.getItem("email")
        const redirectUri=`${plan.payment_link}?client_reference_id=${userId}&prefilled_email=${userEmail}`
        window.location.href = redirectUri
    }

    const handleUpdateOnStripe = (event) => {
        window.open(activeSubscription.customer_portal_link, '_blank')
    }

    return (
        <Grid container spacing={3.75} mb={4}>
            {plans && plans.map((item, index) => {
                let headerBgColor = 'primary.main'
                let sx = null
                if (index % 2) {
                    headerBgColor = 'primary.dark'
                    sx = {
                        transform: 'scale(1)',
                        color: 'common.white',
                        bgcolor: 'primary.light'
                    }
                }
                
                return (
                    <Grid item xs={12} md={6} lg={4}>
                        <PlanCards
                            title={item.price}
                            strike={item.strike_price}
                            subheader={item.name}
                            headerSx={{
                                textAlign: 'center',
                                bgcolor: headerBgColor,
                            }}
                            sx={sx}
                            activePlan={activeSubscription.plan_name}
                        >
                            <PlanFeatures
                                activeSubscription={activeSubscription}
                                trialDays={item.trial_days}
                                features={item.features}
                            />
                            {activeSubscription.plan_name === "Free" ? (
                                <Button
                                    variant={"contained"}
                                    disableElevation
                                    sx={{mb: 2}}
                                    planId={item.id}
                                    onClick={handleSelectPlan}>
                                        {user?.subscription?.max_trial_period > 0 ? (
                                            "Start Free Trial"
                                        ) : (
                                            "Upgrade Plan"
                                        )}
                                        
                                        
                                </Button>
                            ) : (
                                <Button
                                    variant={"contained"}
                                    disableElevation
                                    sx={{mb: 2}}
                                    planId={item.id}
                                    onClick={handleUpdateOnStripe}>
                                        Update Plan
                                </Button>
                            )}
                            
                        </PlanCards>
                    </Grid>
                )
            })}
        </Grid>
    )
}

export default PlanOutput;