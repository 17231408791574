import React, { useEffect, useState } from 'react';
import Div from "@jumbo/shared/Div";
import Chip from "@mui/material/Chip";
import SentMessageContent from "./SentMessageContent";
import ReceivedMessageContent from "./ReceivedMessageContent";

const ActiveConversationChat = ({conversation, lastMessage, scrollbarRef}) => {
    const [isLoadingResponse, setIsLoadingResponse] = useState(false)
    const [isLoadingMessage, setIsLoadingMessage] = useState(false)
    // Between each submission by the user, we're artificially delaying the
    // loading of the system response in order to feel more "real"
    // Using milliseconds to prevent a bug due to inaccurancy.
    const responseDelayMilliseconds = 1000
    const loadingDelayMilliseconds = 1000

    // TODO: Improve
    useEffect(() => {
        if(lastMessage) {
            const currentTimeInMilliseconds = Date.now()
            const lastMessageSentAt = lastMessage.sent_at_ms

            // Time until we show the system response box
            const loadingResponseUntil = lastMessageSentAt + responseDelayMilliseconds
            // Time until we show the actual system message
            const loadingMessageUntil = loadingResponseUntil + loadingDelayMilliseconds

            if (currentTimeInMilliseconds < loadingResponseUntil) {
                // Freshly recieved message, delay showing the system response box
                const responseTimeDifference = loadingResponseUntil - currentTimeInMilliseconds
                const messageTimeDifference = loadingMessageUntil - currentTimeInMilliseconds

                setIsLoadingResponse(true)
                setIsLoadingMessage(true)

                setTimeout(() => {
                    setIsLoadingResponse(false)
                    // Hackyhacks for scrolling to the bottom
                    if (scrollbarRef) 
                        setTimeout(scrollbarRef.current?.scrollToBottom, 1)
                }, responseTimeDifference)
                setTimeout(() => {
                    setIsLoadingMessage(false)
                }, messageTimeDifference)

            } else if (currentTimeInMilliseconds > loadingResponseUntil && currentTimeInMilliseconds < loadingMessageUntil) {
                // Show the loading spinner
                const timeDifference = loadingMessageUntil - currentTimeInMilliseconds
                setIsLoadingMessage(true)
                setTimeout(() => {
                    setIsLoadingMessage(false)
                }, timeDifference)
            } else {
                // Don't do anything special
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastMessage, isLoadingResponse, isLoadingMessage])

    return (
        <React.Fragment>
            <Div sx={{
                position: 'relative',
                textAlign: 'center',
                mb: 2,

                '&:after': {
                    display: 'inline-block',
                    content: "''",
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    height: '1px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    backgroundColor: 'divider',
                },
            }}>
                <Chip
                    label={conversation?.sent_date}
                    variant="outlined"
                    sx={{
                        position: 'relative',
                        zIndex: 1,
                        bgcolor: theme => theme.palette.background.paper,
                        borderColor: 'divider',
                        borderRadius: 2
                    }}
                />
            </Div>
            {
                conversation?.messages?.map((message, index) => {
                    if(lastMessage.id === message.id && message.message_type === 'text' && lastMessage.send_by !== "1" ) {
                        return (
                            <React.Fragment key={index}>
                                <ReceivedMessageContent
                                    message={lastMessage}
                                    isLoadingResponse={isLoadingResponse}
                                    isLoadingMessage={isLoadingMessage}
                                />
                            </React.Fragment>
                        )
                    } else {
                        return (
                            <React.Fragment key={index}>
                                {
                                    message?.sent_by === 1 ? (
                                        <SentMessageContent message={message}/>
    
                                    ) : (
                                        <ReceivedMessageContent message={message}/>
                                    )
                                }
                            </React.Fragment>
                        )
                    }
                    
                })
            }
        </React.Fragment>
    );
};
/* Todo conversation prop define */
export default ActiveConversationChat;
