import React from 'react';
import {Button, Card, CardContent, CardHeader, Typography} from "@mui/material";
import PropTypes from 'prop-types';

const PlanCards = ({title, strike, subheader, children, headerSx, sx, activePlan,}) => {
    const isActive = subheader === activePlan
    return (
        <Card
            sx={{
                transform: 'scale(.95)',
                transition: 'all .2s ease-in-out',
                '&:hover': {
                    transform: 'scale(1)',
                },
                ...sx
            }}
        >
            <CardHeader
                title={
                    <Typography
                        variant={"h2"}
                        fontSize={36}
                        fontWeight={500}
                        color="inherit"
                    >
                        {isActive ? (
                            <div>
                                <Button
                                    disableElevation
                                    disableRipple
                                    variant={"contained"}
                                    size={"large"}
                                    sx={{
                                        bgcolor: '#F5F7FA',
                                        color: 'primary.main',
                                        '&:hover': {
                                            bgcolor: '#F5F5F5'
                                        },
                                        cursor: 'default',
                                        marginBottom: '12px'
                                    }}
                                >Active Plan</Button>
                            </div>
                        ) : (
                            <Typography sx={{ textDecoration: 'line-through', fontSize: '1.25rem'}}>
                                {strike}
                            </Typography>
                        )}
                        
                        {title}<span style={{fontSize: "14px"}}>/mo</span>
                    </Typography>
                    
                }
                subheader={
                    <Typography
                        variant={"h5"}
                        color="inherit"
                        sx={{
                            textTransform: 'uppercase',
                            letterSpacing: 3,
                            mb: 0
                        }}
                    >
                        {subheader}
                    </Typography>
                }
                sx={{
                    pt: isActive ? 2 : 5,
                    pb: 5,
                    color: 'common.white',
                    ...headerSx
                }}
            />
            <CardContent
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    pt: 5,
                }}
            >
                {children}
            </CardContent>
        </Card>
    );
};
/* Todo prop define */
PlanCards.propTypes = {
    title: PropTypes.node,
    children: PropTypes.node,
}

export default PlanCards;
