import React from 'react';
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import JumboCardFeatured from "@jumbo/components/JumboCardFeatured";
import {ASSET_IMAGES} from "../../../utils/constants/paths";
import {getAssetPath} from "../../../utils/appHelpers";
import {useNavigate} from 'react-router-dom';

const CardLeft = ({height}) => {
    const navigate = useNavigate();

    const handleNavigate = () => {
        navigate('/help-center/how-to-use-rdy');
    }

    return (
        <JumboCardFeatured
            showTick
            tickSx={{bgcolor: 'success.main'}}
            direction={"column"}
            textAlign={"center"}
            imgSrc={getAssetPath(`${ASSET_IMAGES}/help-center-how-to-use-rdy.png`,"380x175")}
        >
            <Typography variant={"h4"} mb={2}>How to Use Rdy</Typography>
            <Typography mb={3}>Read about how to use us and how to get the most out of your project analysis.</Typography>
            <Button variant={"contained"} onClick={handleNavigate}>Learn More</Button>
        </JumboCardFeatured>
    );
};
/* Todo height prop define */
export default CardLeft;
