import React, { useEffect } from 'react';
import {Grid, CardMedia, CardContent, Typography} from "@mui/material";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import {ASSET_IMAGES} from "../../../../utils/constants/paths";

const OurMission = () => {
    useEffect(() => {
        document.title = 'Our Mission - Rdy';

        window.scrollTo(0, 0);
    }, [])

    return (
        <React.Fragment>
            <JumboCardQuick
                title={
                    <CardContent sx={{textAlign: 'center', color: 'common.white', bgcolor: 'warning.main', padding: 0, height: '312px', borderRadius: '12px'}}>
                        <CardMedia
                            component="img"
                            height="312"
                            image={`${ASSET_IMAGES}/help-center-our-mission.jpg`}
                            alt="Our Mission"
                            sx={{ borderRadius: '12px' }}
                        />
                    </CardContent>
                }
                wrapperSx={{pt: 0}}
            >
                <Grid container spacing={3.75}>
                    <Grid item xs={12} md={12}>
                        <Typography variant="h2" color="text.primary">Our Mission</Typography>

                        <Typography variant="body1" color="text.secondary" mb={1}>
                            We understand that there are things that an AI can't know yet, because it's not their responsibility, but ours. There are things we either forget to think about, or decide aren't worth our time. And there are decisions that only we should be making, because we are the only ones aware of the complexities and intricacies involved. 
                        </Typography>
                         
                        <Typography variant="body1" color="text.secondary" mb={1}>
                            Rdy's purpose is not solely to solve your problems, but to shift your mindset and instill a certain philosophy of critical thinking, whether in the business world or in your own personal life.
                        </Typography>

                        <Typography variant="body1" color="text.secondary" mb={2}>
                            For every project we work on together, we ask questions not just as a means for you to understand your involvement in a project, but to support and care about what's around you.
                        </Typography>

                        <Typography variant="h4" color="text.primary">The Cycle of Decision Making</Typography>

                        <Typography variant="body1" color="text.secondary" mb={1}>
                            Business inherently relies on quick results, fast turnaround times and short development cycles. These limitations cause us to make quicker decisions with a sometimes limited amount of knowledge available to us. Quick decisions sometimes lead to significant downstream negative impacts on you, your organization and the team around you.
                        </Typography>

                        <Typography variant="body1" color="text.secondary" mb={1}>
                            The danger of "fast" comes primarily from a lack of planning, which leads to an inability to forsee the consequences of ones actions. 
                        </Typography>

                        <Typography variant="body1" color="text.secondary" mb={2}>
                            Bad outcomes then lead us to spend more resources trying to cover for our flaws by optimizing workflows, holding more meetings and otherwise band-aid fixing the fundamental underlying problem. 
                        </Typography>

                        <Typography variant="h4" color="text.primary">Our Target</Typography>

                        <Typography variant="body1" color="text.secondary" mb={1}>
                            Sometimes we're thrust into the position of holding this responsibility, to make the right decision without as much comfort or confidence as we'd want. While Rdy can't solve the problem of grant you more time, we want to help identify what's missing and where you should spend most of your time to have the biggest impact. 
                        </Typography>

                        <Typography variant="body1" color="text.secondary" mb={1}>
                            Rdy isn't just a software and we aren't just a company. We're trying to positively impact the world, one person at a time, however we can. 
                        </Typography>
                    </Grid>
                </Grid>
            </JumboCardQuick>
        </React.Fragment>
    );
};

export default OurMission;
