import React  from 'react';
import {Alert, AlertTitle, Grid} from "@mui/material"; 

function CustomIcon() {
    return (
      <div
        style={{ margin: '0.25rem', fontSize: '2.5rem' }}
      >
        🙌
      </div>
    );
}

const ReferralNotice = () => {
    return (
        <Grid container spacing={1} sx={{ mt: 0 }}>
            <Grid item sm={3}></Grid>
            <Grid item sm={6}>
                <Alert severity="warning" icon={<CustomIcon/>}>
                    <AlertTitle>Welcome Friend!</AlertTitle>
                    <span style={{ fontWeight: 300 }}>As a thanks for checking out Rdy, we're upgrading all of your subscriptions to a </span><span style={{ fontWeight: 400 }}>30-day free trial!</span>
                </Alert>
            </Grid>
        </Grid>
    )
}

export default ReferralNotice;