import React from 'react';
import Div from "@jumbo/shared/Div";
import {Typography} from "@mui/material";
import Paper from "@mui/material/Paper";

import Badge from '@mui/material/Badge';

const SentMessageContent = ({message}) => {
    return (
        <Div
            sx={{
                display: 'flex',
                textAlign: 'right',
                alignItems: 'flex-start',
                flexDirection: 'row-reverse',
                mb: 2,
                px: 3,
            }}
        >
            <div className="Message-root" style={{ maxWidth: '80%' }}>
                <div className="Message-item">
                    <Badge anchorOrigin={{ horizontal: 'left', vertical: 'top' }} badgeContent={message?.message_badge_value} color={message?.message_badge_color} sx={{ fontWeight: 400 }}>
                        <Paper elevation={0} sx={{p: theme => theme.spacing(1, 2), bgcolor: theme => theme.palette.divider}}>
                            { (message?.message !== null && message?.message.length) > 0 ? (
                                <Typography sx={{ fontWeight: '300'}} variant={"body1"}>{message?.message}</Typography>
                            ) : (
                                <Typography sx={{ fontWeight: '300'}} variant={"body1"} color={"#a2a2a2"}>No context</Typography>
                            )}
                        </Paper>
                    </Badge>
                </div>
            </div>
        </Div>
    );
};

export default SentMessageContent;
