import React  from 'react';
import {Alert, AlertTitle, Grid} from "@mui/material"; 

const OverdueNotice = ({customerPortalLink}) => {
    return (
        <Grid container spacing={1} sx={{ mt: 0 }}>
            <Grid item sm={3}></Grid>
            <Grid item sm={6}>
                <Alert severity="error">
                    <AlertTitle>Subscription Overdue!</AlertTitle>
                    <span style={{ fontWeight: 300 }}>
                        We've been unable to collect payment on your subscription. You will not be able to use Rdy until this is addressed.
                    </span>
                    <br/>
                    <a href={customerPortalLink} target="_blank" rel="noreferrer">Click here to review your card details on Stripe.</a>
                </Alert>
            </Grid>
        </Grid>
    )
}

export default OverdueNotice;