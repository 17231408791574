import React, { useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import Div from "@jumbo/shared/Div";
import { Box, Grid, Typography } from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';
import PanToolOutlinedIcon from '@mui/icons-material/PanToolOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';

import ReportChart from './ReportChart';

const treeViewTheme = createTheme({
    components: {
      MuiTreeItem: {
        styleOverrides: {
          root: {
            borderRadius: '12px'
          },
          content: {
            '&.Mui-selected': {
              backgroundColor: 'transparent !important',
            },
            '&.Mui-focused': {
                backgroundColor: 'transparent !important',
            },
          },
          label: {
            fontFamily: 'NoirPro,Arial',
            fontWeight: '300',
            fontSize: '.875rem',
          }
        },
      },
    },
});

const ResponseTree = ({chartData, results, parentNodeIds, childNodeIds, answerNodeIds}) => {
    const [expanded, setExpanded] = React.useState([1]);
    const [selected, setSelected] = React.useState([]);

    const [selectedParentNode, setSelectedParentNode] = useState(1)

    const handleSelect = (event, nodeIds) => {
        if(parentNodeIds.includes(parseInt(nodeIds[0]))) {
            // Can select and expand parent
            setSelected(nodeIds);
            setSelectedParentNode(nodeIds[0])
            setExpanded([nodeIds[0]])
        } else if (childNodeIds.includes(parseInt(nodeIds[0]))) {
            // Can select and expand child
            setSelected(nodeIds);
            setExpanded([selectedParentNode, nodeIds[0]])
        } else if (answerNodeIds.includes(parseInt(nodeIds[0]))) {
            // Can't select answer, to prevent removing highlighting
        }
    };

    return (
        <React.Fragment>
            <JumboCardQuick title={"Summary"} wrapperSx={{backgroundColor: 'background.paper', pt: 0}}>
                <Div sx={{width: '100%', maxWidth: 500}}>
                    <Typography variant="body1" sx={{ mb: 2 }} gutterBottom>
                        We've processed your results based on the inputs you provided us, with your overall confidence score and our feedback shown here.
                    </Typography>

                    <Typography variant="body1" sx={{ mb: 2 }} gutterBottom>
                        The scale value you provided for each question is factored as a confidence value. A low rating generally indicates uncertainty or ambiguity, and stands as a reason to spend additional time researching and preparing prior to starting your project.
                    </Typography>

                    <Typography variant="body1" sx={{ mb: 0 }} gutterBottom>
                        It's in you and your teams best interest to ensure a high level of confidence across all aspects of the project in order to optimize spending your valuable resources.
                    </Typography>
                    
                </Div>
                <Grid container columnSpacing={2} sx={{ mt: 2 }}>
                    <Grid item xs={7} alignSelf={"center"} >
                        <Typography variant={"body1"} color={"text.secondary"} sx={{whiteSpace: 'nowrap', mb: 0, fontSize: '1rem'}}>Score by Section</Typography>
                        <Typography variant="body1" sx={{ mt: 1.5, mb: 2 }} gutterBottom>
                            Each major topic's weighted confidence average based on your responses
                    </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Div sx={{my: '-2px'}}>
                            <ReportChart
                                chartData={chartData}
                            />
                        </Div>
                    </Grid>
                </Grid>
            </JumboCardQuick>
            
            <Div style={{ marginTop: 20 }} />
            <JumboCardQuick title={"Responses & Feedback"}wrapperSx={{backgroundColor: 'background.paper', pt: 0}}>
                <Grid container columnSpacing={2} sx={{ mt: 0, mb: 1 }}>
                    <Grid item xs={8} sx={{ paddingTop: '6px'}}>
                        Below is a summarization of your inputs provided during the analysis, along with our feedback.
                    </Grid>
                    <Grid item xs={4}>
                        <Box alignItems="center" textAlign="center">
                            <ThumbUpOutlinedIcon sx={{fontSize: 12}}/> / <PanToolOutlinedIcon sx={{fontSize: 12}}/> / <ThumbDownOutlinedIcon sx={{fontSize: 12}}/> &nbsp;Confidence
                        </Box>
                        <Box alignItems="center" textAlign="center">
                            <ChatBubbleOutlineOutlinedIcon sx={{fontSize: 12}}/> &nbsp;Your Notes
                        </Box>
                        <Box alignItems="center" textAlign="center">
                            <ErrorOutlineOutlinedIcon sx={{fontSize: 12}}/> &nbsp;Our Feedback
                        </Box>
                    </Grid>
                </Grid>
                
                <Div sx={{height: 400, flexGrow: 1, overflowY: 'auto', mt: 2, borderRadius: '12px'}}>
                    <ThemeProvider theme={treeViewTheme}>
                        <TreeView
                            aria-label="controlled"
                            defaultCollapseIcon={<ExpandMoreIcon/>}
                            defaultExpandIcon={<ChevronRightIcon/>}
                            defaultEndIcon={<FiberManualRecordOutlinedIcon sx={{ fontSize: 6}}/>}
                            expanded={expanded}
                            selected={selected}
                            onNodeSelect={handleSelect}
                            multiSelect
                        >
                            {results && results.map((item) => (
                                <TreeItem key={item.node_id} nodeId={String(item.node_id)} label={`${item.name} - ${item.score}%`}>
                                    {/* Topic level */}
                                    {item.children.map((child) => (
                                        <TreeItem key={child.node_id} nodeId={String(child.node_id)} label={`${child.name}`} sx={{ backgroundColor: child.node_id.toString() === selected[0] ? '#f0f4fd' : 'transparent'}} >
                                            {/* Question level */}
                                            {child.children.map((answer) => {
                                                 // eslint-disable-next-line no-lone-blocks
                                                {/* Answer level */}
                                                const score = parseInt(answer.score)                        
                                                let icon;
                                                let backgroundColor;

                                                switch(true) {
                                                    case score >= 1 && score <= 4:
                                                        icon = <ThumbDownOutlinedIcon/>
                                                        backgroundColor = '#f7c1c7'
                                                        break;
                                                    case score >= 5 && score <= 7:
                                                        icon = <PanToolOutlinedIcon/>
                                                        backgroundColor = '#f2d4a8'
                                                        break;
                                                    case score >= 8 && score <= 9:
                                                        icon = <ThumbUpOutlinedIcon/>
                                                        backgroundColor = '#b2d9cd'
                                                        break;
                                                    case score === 10:
                                                        // Badly implicit feedback note
                                                        icon = <ErrorOutlineOutlinedIcon/>
                                                        break
                                                    default: 
                                                        icon = <ChatBubbleOutlineOutlinedIcon/>
                                                        break;
                                                }

                                                return <TreeItem key={answer.node_id} nodeId={String(answer.node_id)} icon={icon} label={`${answer.name}`} sx={{ borderRadius: '6px', backgroundColor: backgroundColor}}/>
                                            })}
                                        </TreeItem>
                                    ))}
                                </TreeItem>
                            ))}
                        </TreeView>
                    </ThemeProvider>
                </Div>
            </JumboCardQuick>
        </React.Fragment>
    );
};

export default ResponseTree;
