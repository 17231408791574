import React from "react";
import Dashboard from "../pages/rdy/dashboard/Dashboard";
import ChatApp from "../pages/rdy/chat"
import Projects from "../pages/rdy/projects/Projects"
import ProjectNew from "../pages/rdy/projectNew/ProjectNew"
import ProjectUpload from "../pages/rdy/projectUpload/ProjectUpload"
import ProjectIntroduction from "../pages/rdy/projectIntroduction/ProjectIntroduction"
import ProjectResults from "../pages/rdy/projectResults/ProjectResults"

import HelpCenter from "../pages/rdy/helpCenter/HelpCenter"
import HowToUseRdy from "../pages/rdy/helpCenter/articles/HowToUseRdy"
import OurMission from "../pages/rdy/helpCenter/articles/OurMission"
import YourDataYourPrivacy from "../pages/rdy/helpCenter/articles/YourDataYourPrivacy"
import WhatKindOfDocumentsDoesRdyUse from "../pages/rdy/helpCenter/articles/WhatKindOfDocumentsDoesRdyUse"
import UnderstandingYourResults from "../pages/rdy/helpCenter/articles/UnderstandingYourResults"
import DocumentAnalysis from "../pages/rdy/helpCenter/articles/DocumentAnalysis"

import SettingsProfile from "../pages/rdy/settingsProfile/SettingsProfile"
import SettingsPlans from "../pages/rdy/settingsPlans/SettingsPlans"
import SettingsSecurity from "../pages/rdy/settingsSecurity/SettingsSecurity"
import Page from "@jumbo/shared/Page";

const rdyRoutes = [
    {
        path: "/dashboard",
        element: <Page component={Dashboard} />
    },
    {
        path: [
            "/chats",
            "/chats/conversation/:project_id",
        ],
        element: <Page component={ChatApp} />,
    },
    {
        path: [
            "/projects",
            "/projects/:project_id",
        ],
        element: <Page component={Projects} />
    },
    {
        path: "/projects/new",
        element: <Page component={ProjectNew} />
    },
    {
        path: "/projects/:project_id/upload",
        element: <Page component={ProjectUpload} />
    },
    {
        path: "/projects/:project_id/introduction",
        element: <Page component={ProjectIntroduction} />
    },
    {
        path: "/projects/:project_id/results",
        element: <Page component={ProjectResults} />
    },
    {
        path: "/help-center",
        element: <Page component={HelpCenter} />
    },
    {
        path: "/help-center/how-to-use-rdy",
        element: <Page component={HowToUseRdy} />
    },
    {
        path: "/help-center/our-mission",
        element: <Page component={OurMission} />
    },
    {
        path: "/help-center/your-data-your-privacy",
        element: <Page component={YourDataYourPrivacy} />
    },
    {
        path: "/help-center/what-kind-of-documents-does-rdy-use",
        element: <Page component={WhatKindOfDocumentsDoesRdyUse} />
    },
    {
        path: "/help-center/understanding-your-results",
        element: <Page component={UnderstandingYourResults} />
    },
    {
        path: "/help-center/document-analysis",
        element: <Page component={DocumentAnalysis} />
    },
    {
        path: "/settings",
        element: <Page component={SettingsProfile} />
    },
    {
        path: "/settings/plans",
        element: <Page component={SettingsPlans} />
    },
    {
        path: "/settings/security",
        element: <Page component={SettingsSecurity} />
    } 
];

export default rdyRoutes;
