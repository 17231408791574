import jwtAuthAxios from "./auth/jwtAuth";
import moment from 'moment';

export const fetchProjectsData = async (userId) => {
    const {data} = await jwtAuthAxios.get("/projects");
    return data;
};

export const fetchActiveProjectData = async () => {
    const sevenDaysFromNow = moment().add(7, 'days');
    const fourteenDaysAgo = moment().subtract(14, 'days');
  
    const formattedFromDate = fourteenDaysAgo.format('DD-MM-YYYY');
    const formattedToDate = sevenDaysFromNow.format('DD-MM-YYYY');

    const {data} = await jwtAuthAxios.get(`/projects?status=active&start_date=${formattedFromDate}&end_date=${formattedToDate}`)
    return data
}

export const fetchCompletedProjectData = async () => {
    const today = moment();
    const sevenDaysAgo = moment().subtract(7, 'days');
  
    const formattedFromDate = sevenDaysAgo.format('DD-MM-YYYY');
    const formattedToDate = today.format('DD-MM-YYYY');

    const {data} = await jwtAuthAxios.get(`/projects?status=complete&start_date=${formattedFromDate}&end_date=${formattedToDate}`)
    // .then(response => { setProjects(response.data)})
    // .catch(error => console.log(error));

    return data
}

// Add other project-related functions as needed