import React, { useEffect } from 'react';
import {Card, CardContent, Link, Typography} from "@mui/material";
import Div from "@jumbo/shared/Div";
import {alpha} from "@mui/material/styles";
import {ASSET_IMAGES} from "../../../utils/constants/paths";
import {getAssetPath} from "../../../utils/appHelpers";
import JumboAppTextField from "@jumbo/components/JumboAppTextField";
import LoadingButton from "@mui/lab/LoadingButton";
import {useNavigate} from "react-router-dom";

import {Form, Formik} from "formik";
import * as yup from "yup";

import useJumboLayoutSidebar from "@jumbo/hooks/useJumboLayoutSidebar";
import jwtAuthAxios from "../../../services/auth/jwtAuth";

import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import {useTheme} from "@mui/material";

const validationSchema = yup.object({
    password: yup
        .string('Enter your password')
        .required('Password is required')
        .min(8, 'Password is too short')
        .max(64, 'Password is too long'),
    passwordConfirmation: yup
        .string('Enter your password')
        .required('Password is required')
        .min(8, 'Password is too short')
        .max(64, 'Password is too long')
        .oneOf([yup.ref('password'), null], 'Passwords must match'),
    resetToken: yup
        .string('Enter the reset token')
        .required('Reset token is required'),
});

const SetPassword = () => {
    const queryString = new URLSearchParams(window.location.search)
    const {setSidebarOptions} = useJumboLayoutSidebar();
    // const [resetToken, setResetToken] = useState(queryString.get('token'));
    const resetToken = queryString.get('token');
    const Swal = useSwalWrapper();
    const theme = useTheme();
    const navigate = useNavigate();
    
    useEffect(() => {
        document.title = 'Set Password - Rdy';

        setSidebarOptions({
            width: 0,
            minWidth: 0
        })
    }, [setSidebarOptions])

    const sweetAlerts = (variant, inputText) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: false,
            didOpen: toast => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
        });
        Toast.fire({
            icon: variant,
            title: inputText,
            background: theme.palette.background.paper,
        });
    };

    const onPasswordSet = (resetToken, password, passwordConfirmation) => {
        const payload = {
            user: {
                reset_password_token: resetToken,
                password: password,
                password_confirmation: passwordConfirmation
            }
        }

        jwtAuthAxios.put("/users/password", payload)
            .then(response => {
                sweetAlerts('success', 'Password saved!');
                navigate("/login");
            })
            .catch(error => {
                sweetAlerts('error', 'The password reset token is invalid')
            })
    };

    const onLogin = (e) => {
        e.preventDefault()
        navigate("/login")
    }

    return (
        <Div sx={{width: 720, maxWidth: '100%', margin: 'auto', p: 4}}>
            <Card
                sx={{
                    display: 'flex',
                    minWidth: 0,
                    flexDirection: {xs: 'column', md: 'row'}
                }}
            >
                <CardContent
                    sx={{
                        flex: '0 1 300px',
                        position: 'relative',
                        background: `#0267a0 url(${getAssetPath(`${ASSET_IMAGES}/registration-set-gradient.jpg`)}) no-repeat center`,
                        backgroundSize: 'cover',

                        '&::after': {
                            display: 'inline-block',
                            position: 'absolute',
                            content: `''`,
                            inset: 0,
                            backgroundColor: alpha('#f19749', .1)
                        }
                    }}
                >
                    <Div
                        sx={{
                            display: 'flex',
                            minWidth: 0,
                            flex: 1,
                            flexDirection: 'column',
                            color: 'common.white',
                            position: 'relative',
                            zIndex: 1,
                            height: '100%',
                            // minHeight: {md: 250}
                        }}
                    >
                        <Div sx={{mb: 2}}>
                            <Typography variant={"h4"} sx={{ color: 'common.white', fontWeight: 300 }} mb={2} mt={2}>
                                Enter your new password to complete the process.
                            </Typography>
                        </Div>
                        <Div sx={{mt: 'auto'}}>
                            <img src={`${ASSET_IMAGES}/logo-128.png`} alt="Rdy AI" width="36" height="36"/>
                        </Div>
                    </Div>
                </CardContent>
                <CardContent sx={{flex: 1, p: 4}}>
                    <Div
                        sx={{
                            display: 'flex',
                            minWidth: 0,
                            flex: 1,
                            flexDirection: 'column',
                            height: '100%'
                        }}
                    >
                        <Formik
                            validateOnChange={true}
                            initialValues={{
                                resetToken: resetToken
                            }}
                            validationSchema={validationSchema}
                            onSubmit={(data, {setSubmitting}) => {
                                setSubmitting(true);
                                onPasswordSet(data.resetToken, data.password, data.passwordConfirmation);
                                setSubmitting(false);
                            }}
                        >
                            {({isSubmitting}) => (
                                <Form noValidate autoComplete="off">
                                    <Div sx={{mb: 1, mt: 1}}>
                                        <JumboAppTextField
                                            fullWidth
                                            name="resetToken"
                                            label="Reset Token"
                                            disabled
                                        />
                                    </Div>
                                    <Div sx={{mb: 1, mt: 1}}>
                                        <JumboAppTextField
                                            fullWidth
                                            name="password"
                                            label="Password"
                                            type="password"
                                        />
                                    </Div>
                                    <Div sx={{mb: 3, mt: 1}}>
                                        <JumboAppTextField
                                            fullWidth
                                            name="passwordConfirmation"
                                            label="Confirm Password"
                                            type="password"
                                        />
                                    </Div>
                                    <LoadingButton
                                        fullWidth
                                        type="submit"
                                        variant="contained"
                                        size="large"
                                        sx={{mb: 3}}
                                        loading={isSubmitting}
                                    >Set Password</LoadingButton>
                                </Form>
                            )}
                        </Formik>
                    </Div>
                </CardContent>
            </Card>
            <Div sx={{
                width: '100%',
                maxWidth: '100%',
                margin: 'auto',
                textAlign: 'center',
                p: 4,
                pt: 2.6,
                pb: 0,
                fontSize: '12px'
            }}>
                Remember your password? <Link href={'/signup'} onClick={onLogin} underline="none">Log in</Link>.
            </Div>
        </Div>
    );
};

export default SetPassword;
