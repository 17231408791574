import React, { useEffect } from 'react';
import Div from "@jumbo/shared/Div";
import {Card, CardContent, Typography, Link,} from "@mui/material";
import {alpha} from "@mui/material/styles";
import {ASSET_IMAGES} from "../../../utils/constants/paths";
import {getAssetPath} from "../../../utils/appHelpers";
import {useNavigate} from "react-router-dom";

import SignupForm from '../signup1/components/SignupForm';
import useJumboLayoutSidebar from "@jumbo/hooks/useJumboLayoutSidebar";

const Signup2 = () => {

    const {setSidebarOptions} = useJumboLayoutSidebar();
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Signup - Rdy';

        setSidebarOptions({
            width: 0,
            minWidth: 0
        })
    }, [setSidebarOptions])

    const onLogin = (e) => {
        e.preventDefault()
        navigate("/login")
    }

    return (
        <Div sx={{
            width: 720,
            maxWidth: '100%',
            margin: 'auto',
            p: 4
        }}>
            <Card
                sx={{
                    display: 'flex',
                    minWidth: 0,
                    flexDirection: {xs: 'column', md: 'row'}
                }}
            >
                <CardContent
                    sx={{
                        flex: '0 1 300px',
                        position: 'relative',
                        background: `#0267a0 url(${getAssetPath(`${ASSET_IMAGES}/registration-signup-gradient.jpg`, "640x428")}) no-repeat center`,
                        backgroundSize: 'cover',

                        '&::after': {
                            display: 'inline-block',
                            position: 'absolute',
                            content: `''`,
                            inset: 0,
                            backgroundColor: alpha('#f19749', .1)
                        }
                    }}
                >
                    <Div
                        sx={{
                            display: 'flex',
                            minWidth: 0,
                            flex: 1,
                            flexDirection: 'column',
                            color: 'common.white',
                            position: 'relative',
                            zIndex: 1,
                            height: '100%',
                            minHeight: {md: 220}
                        }}
                    >
                        <Div sx={{mb: 2}}>
                            <Typography variant={"h4"} sx={{ color: 'common.white', fontWeight: 300 }} mb={2} mt={2}>
                                Create an account to get started!
                            </Typography>
                            <Typography variant={"body1"}>
                                <Link href={"/login"} onClick={onLogin} color={"inherit"} underline={'none'}>Already have an account?</Link>
                            </Typography>
                        </Div>

                        <Div sx={{mt: 'auto'}}>
                            <img src={`${ASSET_IMAGES}/logo-128.png`} alt="Rdy AI" height="36" width="36"/>
                        </Div>
                    </Div>
                </CardContent>
                <CardContent
                    sx={{
                        flex: 1,
                        p: 4
                    }}
                >
                    <SignupForm />
                </CardContent>
            </Card>
            <Div sx={{
                width: '100%',
                maxWidth: '100%',
                margin: 'auto',
                textAlign: 'center',
                p: 4,
                pt: 2.6,
                pb: 0,
                fontSize: '12px'
            }}>
                By registering an account, you agree to our <Link href={'/signup'} underline="none">Terms</Link> and <Link href={'/signup'} underline="none">Privacy Policy</Link>.
            </Div>

        </Div>
    );
};

export default Signup2;
