import React from "react";
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import LiveHelpOutlinedIcon from '@mui/icons-material/LiveHelpOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

const menus = [
    {
        label: "Home",
        type: "section",
        children: [
            {
                uri: "/dashboard",
                label: 'Dashboard',
                type: "nav-item",
                icon: <DashboardOutlinedIcon sx={{fontSize: 20}}/>
            },
            {
                uri: "/projects",
                label: 'Projects',
                type: "nav-item",
                icon: <PendingActionsIcon sx={{fontSize: 20}}/>
            },
            {
                uri: "/chats",
                label: 'Chat',
                type: "nav-item",
                icon: <ChatBubbleOutlineIcon sx={{fontSize: 20}}/>
            },
        ]
    },

    {
        label: "Account",
        type: "section",
        children: [
            {
                uri: "/help-center",
                label: 'Help',
                type: "nav-item",
                icon: <LiveHelpOutlinedIcon sx={{fontSize: 20}}/>
            },
            {
                uri: "/settings",
                label: 'Settings',
                type: "nav-item",
                icon: <SettingsOutlinedIcon sx={{fontSize: 20}}/>
            },
        ]
    }
];

export default menus;
