import jwtAuthAxios from "./auth/jwtAuth";

// Return response data due to hook
export const fetchUserData = async () => {
    const {data} = await jwtAuthAxios.get("/users");
    return data;
};

export const updateUserData = async (payload) => {
    return jwtAuthAxios.put("/users/profile", payload)
}

// Add other user-related functions as needed