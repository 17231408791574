import React from 'react';
import {Typography, Grid} from "@mui/material";
import Div from "@jumbo/shared/Div";

const Footer = () => {

    return (
        <Div sx={{
            py: 1,
            px: {lg: 6, xs: 4},
            borderTop: 1,
            borderColor: 'divider',
            bgcolor: 'background.paper',
        }}>
            <Grid container>
                <Grid item xs={6}>
                    <Div sx={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
                        <Typography variant={"font300"} color={"text.primary"}>
                            <a href="https://www.rdyai.com/privacy-policy" target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: '#3B70DB'}}>Privacy Policy</a>
                            &nbsp; | &nbsp;
                            <a href="https://www.rdyai.com/terms" target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: '#3B70DB'}}>Terms of Service</a>
                        </Typography>
                    </Div>
                </Grid>
                <Grid item xs={6}>
                    <Div sx={{display: 'flex', justifyContent: 'end', alignItems: 'center'}}>
                        <Typography variant={"font300"} color={"text.primary"}>© 2023 Rdy</Typography>
                    </Div>
                </Grid>
            </Grid>
            
            
        </Div>
    );
};

export default Footer;
