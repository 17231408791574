import React, { useState, useEffect } from 'react';
import {Grid} from "@mui/material";
import ResponseTree from './ResponseTree';
import Suggestions from './Suggestions';
// import Feedback from './Feedback';

import jwtAuthAxios from "../../../services/auth/jwtAuth";
import {useParams} from "react-router-dom";


const ProjectResults = () => {
    const [results, setResults] = useState({});
    // TODO: Add validations if the test is actually completed or not
    const {project_id} = useParams();

    useEffect(() => {
        document.title = 'Project Results - Rdy';

        // Block the backend response from giving back anything if they're overdue. 

        // http://localhost:3000/projects/0bc16278-8ed9-4792-89fd-a7876c929842/results

        jwtAuthAxios.get(`/projects/${project_id}/results`)
            .then(response => {
                setResults(response.data)
            })
            .catch(error => {
                if(error.response.status === 402) {
                    console.log("Redirect to plans page")
                }
            });
    }, [project_id])

    return (
        <Grid container spacing={3.75}>
            <Grid item xs={6}>
                <ResponseTree
                    chartData={results.chart_data}
                    results={results.responseTree}
                    parentNodeIds={results.parent_node_ids}
                    childNodeIds={results.child_node_ids}
                    answerNodeIds={results.answer_node_ids}
                />
            </Grid>
            <Grid item xs={6}>
                <Suggestions
                    results={results.suggestions}
                />
                {/* <Div style={{ marginTop: 20 }} /> */}
                {/* <Feedback /> */}
            </Grid>
        </Grid>
    );
};

export default ProjectResults;