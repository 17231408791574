import React from 'react';
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import Div from "@jumbo/shared/Div";
import Typography from "@mui/material/Typography";

import {Button, Step, StepContent, StepLabel, Stepper} from "@mui/material";
import Paper from "@mui/material/Paper";

const Loading = () => {
    return (
        <React.Fragment>
            <JumboCardQuick title={"Suggestions"} wrapperSx={{backgroundColor: 'background.paper', pt: 0}}>
                <Div sx={{width: '100%', maxWidth: 500}}>
                    <Typography variant="body1" sx={{ mb: 2 }} gutterBottom>
                        Your results are loading...
                    </Typography>
                </Div>
            </JumboCardQuick>
        </React.Fragment>
    )
}

const Suggestions = ({results}) => {
    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const steps = [
        {
            label: "Origin",
            description: "Build a stronger understanding of the start of the problem, as a detailed understanding will underpin your ability to make the right decisions."
        },
        {
            label: "Root Cause",
            description: "Spend more time investigating the original cause and understanding the initial event(s) that led up to the cause of your problem."
        },
        {
            label: "Consequences",
            description: "Spend more time reviewing in thorough detail all of the possible consequences that you may face, as this can have an unexpectedly large negative impact on your progress or organization if you're not careful."
        },
        {
            label: "People (Problem)",
            description: "Whoever you're solving the problem for, you need to evaluate in more detail why you're doing this for them, and what their actual benefit is. "
        },
        {
            label: "Potential Solutions",
            description: "Your lack of confidence in the current set of solutions means you may benefit from reassessing all possible options more thoroughly, until you find a suitable and comfortable approach to your problem."
        },
        {
            label: "Cost & Benefits",
            description: "Your lack of clarity with regards to pros and cons could lead you and your team down a path of resource overconsumption with a significant lack of positive benefit. The balancing of the equation is necessary for any legitimate and practical solution"
        },
        {
            label: "People (Solutions)",
            description: "Think more about how the decision-makers of the proposed solutions will be benefiting, and if the conditions of success are realistically moving in the right direction."
        },
        {
            label: "Success Metrics",
            description: "Optimization and improvements can't be had without precise data. Greater precision on success metrics are what enable you and your team to grow and scale, rather than to simply fix a problem."
        },
        {
            label: "Preferred Solution",
            description: "If all else is handled properly, only then will you be in a position to make a fully well-informed and accurately judged solution."
        }
    ];

    if (results === undefined) {
        return(<Loading/>)
    }

    return (
        <React.Fragment>
            <JumboCardQuick title={"Suggestions"} wrapperSx={{backgroundColor: 'background.paper', pt: 0}}>
                <Div sx={{width: '100%', maxWidth: 500}}>
                    {results.length > 0 ? (
                        <Typography variant="body1" sx={{ mb: 2 }} gutterBottom>
                            In order to meet a minimum comfortable level of confidence for your project, we suggest you to prioritize the following
                        </Typography>
                    ) : (
                        <React.Fragment>
                            <Typography variant="body1" sx={{ mb: 2 }} gutterBottom>
                                Based on your exceptional score, we don't have any particular recommendations that we can give you.
                            </Typography>

                            <Typography variant="body1" sx={{ mb: 2 }} gutterBottom>
                                Your comfort with the current state of the project indicate that you are confident in all of it's important aspects, and we unfortunately don't have any critical feedback for you.
                            </Typography>
                        </React.Fragment>
                    )}

                    {results.length > 0 && (
                        <React.Fragment>
                            <Div sx={{maxWidth: 400}}>
                                <Stepper activeStep={activeStep} orientation="vertical">
                                    {results.map((step, index) => (
                                        <Step key={step.name}>
                                            <StepLabel
                                                optional={
                                                    index === results.length - 1 ? (
                                                        <Typography variant="caption">Last step</Typography>
                                                    ) : null
                                                }
                                            >
                                                {step.name}
                                            </StepLabel>
                                            <StepContent>
                                                <Typography>{step.content}</Typography>
                                                <Div sx={{mb: 0}}>
                                                    <div>
                                                        <Button
                                                            variant="contained"
                                                            onClick={handleNext}
                                                            sx={{mt: 1, mr: 1}}
                                                        >
                                                            {index === steps.length - 1 ? 'Finish' : 'Continue'}
                                                        </Button>
                                                        <Button
                                                            disabled={index === 0}
                                                            onClick={handleBack}
                                                            sx={{mt: 1, mr: 1}}
                                                        >
                                                            Back
                                                        </Button>
                                                    </div>
                                                </Div>
                                            </StepContent>
                                        </Step>
                                    ))}
                                </Stepper>
                                {results && activeStep === results.length && (
                                    <Paper square elevation={0} sx={{ paddingTop: 1, paddingLeft: 3}}>
                                        <Button variant="contained" onClick={handleReset} sx={{mt: 1, mr: 1}}>
                                            Restart
                                        </Button>
                                    </Paper>
                                )}
                            </Div>

                            <Typography variant="body1" sx={{ mt: 4, mb: 2 }} gutterBottom>
                                Our suggestions are made to the best of our knowledge of your project, but we acknowledge there may be generalizations or particularities that aren't easily communicated. 
                            </Typography>

                            <Typography variant="body1" sx={{ mb: 2 }} gutterBottom>
                                Rdy's sole mission is to help you identify areas that may have been underdeveloped or overlooked in order for you to be more confident and comfortable in executing on your project.
                            </Typography>
                        </React.Fragment>
                    )}
                </Div>
            </JumboCardQuick>
        </React.Fragment>
    );
};

export default Suggestions;
