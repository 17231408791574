import React, { useContext, useState, useEffect } from 'react';
import { makeStyles } from "@mui/styles";
import {useMutation} from "react-query";
import Div from "@jumbo/shared/Div";
import useChatApp from "../../hooks/useChatApp";
import {chatService} from "../../../../../services/chat-services";
import SendIcon from '@mui/icons-material/Send';
import {useNavigate} from "react-router-dom";
import {Chip, Typography, Tooltip, Button, Slider, TextField, Grid, Stack, Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from '@mui/material/styles';

import { UserContext } from '../../../../../contexts/DataContext';

const CustomAccordion = styled(Accordion)(({ theme }) => {
    return {
      boxShadow: 'none', // this styles directly apply to accordion
      backgroundColor: '#f5f7fa',
      '.MuiAccordionDetails-root': {},
      '.MuiAccordionSummary-root': {}, // this apply to Summary
    };
  });

  const useStyles = makeStyles((theme) => ({
    summary: {
      flexDirection: 'row-reverse',
      justifyContent: 'space-between',
      padding: '0px'
    },
    content: {
        marginLeft: '16px !important',
      marginRight: '16px !important',
    },
    expandIcon: {
      marginRight: 'auto',
    },
  }));
  


const ActiveConversationFooter = () => {
    const navigate = useNavigate();
    const { user } = useContext(UserContext);
    const classes = useStyles();

    const [completed, setCompleted] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    // const form
    // const [formDisabled, setFormDisabled] = useState(true);

    const planName = user ? user.subscription.plan_name : null
    const planStatus = user ? user.subscription.status : null
    const loadingTimeMilliseconds = 2000 // Correlates with ActiveConversationChat.js

    let formDisabled = true

    if(planName !== 'Free' && planStatus === 'active') {
        formDisabled = false
    }

    // TODO: Review this, as if this breaks, it will cause a lot of problems for the user
    // const [formDisabled, setFormDisabled] = useState(true);
    // TODO: Fix this error that seems to be caused by the initial state, I think
    const [marks, setMarks] = useState([{
        value: '5',
        label: ' ',
        hover: 'Loading...'
    }])

    const valueLabelFormat = (value) => {
        if (marks !== undefined && marks.length > 0) {
            // Use the hover value, rather than the actual value
            return marks.find((element) => parseInt(element.value) === parseInt(value)).hover;
        }
    }

    const {
        activeConversation,
        activeConversationRef,
        recentConversationsListRef,
    } = useChatApp();

    useEffect(() => {
        // Suggestion handling

        // How the fuck does this work again?

        // Marks handling
        setMarks([{
            value: '5',
            label: ' ',
            hover: 'Loading...'
        }])

        // Update marks when the new message has finished loading
        const currentTimeInMilliseconds = Date.now();
        const difference = finishedLoadingAt() - currentTimeInMilliseconds

        setTimeout(() => {
            setMarks(activeConversation?.last_message?.answer_format)
            setIsLoaded(true)
        }, difference)

        // Completion handling
        if (activeConversation?.last_message?.message_type === 'complete') {
            // If the last message marks the completion of the conversation, we load the
            // "Continue" button after the intended load time
            setTimeout(() => {
                setCompleted(true)
            }, difference)
            
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeConversation])

    const addMessageMutation = useMutation(chatService.addConversationMessage, {
        onSuccess: () => {
            activeConversationRef?.current?.refresh();
            recentConversationsListRef?.current?.refresh();
        }
    });

    // Unfortunately we need to default to 5 for the time being
    const [sliderValue, setSliderValue] = useState(5);
    const [message, setMessage] = React.useState('');

    const onSliderChange = (event, newValue) => {
        setSliderValue(newValue);
      };

    const resetForm = () => {
        setMarks([{
            value: '5',
            label: ' ',
            hover: 'Loading...'
        }])
        setSliderValue(5)
        setMessage('')
        setIsLoaded(false)
    }

    // TODO: Improve
    const finishedLoadingAt = () => {
        if(activeConversation?.last_message) {
            const lastMessageSentAt = activeConversation.last_message.sent_at_ms
            const loadingUntil = lastMessageSentAt + loadingTimeMilliseconds
            return loadingUntil
        }
    }

    const isFinishedLoading = () => {
        if(activeConversation?.last_message) {
            const currentTimeInMilliseconds = Date.now();
            if (currentTimeInMilliseconds > finishedLoadingAt()) {
                setIsLoaded(true)
                return true
            } else {
                return false
            }

        } else {
            return false
        }
    }

    const onSubmit = () => {
        const mutationData = {
            projectId: activeConversation.project_id,
            conversationId: activeConversation.id,
            conversationResponseId: activeConversation.last_message.id,
            content: message.trim(),
            confidence_value: sliderValue
        }
        resetForm()

        addMessageMutation.mutate(mutationData)
    }

    const onEnterSubmit = (event) => {
        if (event.key === 'Enter' && message && isFinishedLoading()) {
            onSubmit()
        }
    };

    const onClickSubmit = () => {
        if (isFinishedLoading()) {
            onSubmit()
        }
    }

    const onContinue = () => {
        navigate(`/projects/${activeConversation?.project_id}/results`)
    }

    return (
        <React.Fragment>
        {!completed && (<Div
            sx={{
                display: 'flex',
                alignItems: 'center',
                p: theme => theme.spacing(0, 1, 0, 1),
                borderTop: 1,
                borderTopColor: 'divider',
                bgcolor: theme => theme.palette.action.hover
            }}
        >
            <Grid container>
            {/* TODO: Do not show anything if no document has been uploaded */}
                {activeConversation?.last_message?.analysis_text && isLoaded ? (
                    <Grid xs={12}>
                        <CustomAccordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                IconButtonProps={{ edge: 'start', className: classes.expandIcon }}
                                classes={{ content: classes.content, root: classes.summary }}
                                sx={{
                                    '&.Mui-expanded': {
                                        minHeight: '46px',
                                    },
                                    '& .MuiAccordionSummary-content': {
                                        '&.Mui-expanded': {
                                           marginTop: '0px !important',
                                           marginBottom: '0px !important'
                                        }
                                    },
                                }}
                            >
                                <Chip size={"small"} label={"Rdy Analysis"} color={"primary"}/>
                                &nbsp; 
                                <Chip size={"small"} label={activeConversation?.last_message?.analysis_value_formatted} variant="outlined" color={"primary"}/>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    {activeConversation?.last_message?.analysis_text}
                                </Typography>
                            </AccordionDetails>
                        </CustomAccordion>
                    </Grid>
                ) : (
                    <Grid xs={12} sx={{ pl: '16px', pr: '16px', mt: '12px', mb: '12px' }}>
                        <Chip size={"small"} label={"Rdy Analysis"} color={"default"} />
                    </Grid>
                )}
            </Grid>
        </Div>)}
        <Div
            sx={{
                display: 'flex',
                alignItems: 'center',
                p: theme => theme.spacing(2, 3),
                borderTop: 1,
                borderTopColor: 'divider',
                bgcolor: theme => theme.palette.action.hover,
                height: '89px'
            }}
        >
            {completed ? (
                <Grid container spacing={3.75} sx={{ pl: 2, pr: 2}}>
                    <Grid item xs={12}>
                        <Stack direction="row" spacing={2} style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button variant="contained" endIcon={<SendIcon/>} onClick={onContinue} sx={{ fontWeight: 300 }}>
                                Continue
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            ) : (
                <Tooltip title={formDisabled ? "You need an active subscription to use this" : null}>
                <Grid container spacing={3.75} sx={{ pl: 2, pr: 2 }}>
                    <Grid item xs={4}>
                        <Slider
                            aria-label="Custom marks"
                            value={sliderValue}
                            onChange={onSliderChange}
                            valueLabelFormat={valueLabelFormat}
                            step={null}
                            valueLabelDisplay="auto"
                            marks={marks}
                            min={1}
                            max={9}
                            disabled={!isLoaded || formDisabled}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <Grid container sx={{ mt: 0.8, mx: 2 }}>
                            <Grid item xs={11}>
                                <TextField
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    size={'small'}
                                    placeholder={"Type any relevant notes..."}
                                    onKeyPress={onEnterSubmit}
                                    fullWidth
                                    inputProps={{ maxLength: 256 }}
                                    disabled={!isLoaded || formDisabled}
                                    sx={{
                                        backgroundColor: theme => theme.palette.background.paper
                                    }}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <Button variant="contained" onClick={onClickSubmit} sx={{ minWidth: '36px', width: '36px' }} disabled={!isLoaded || formDisabled}>
                                    <SendIcon sx={{ width: '18px' }}/>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                </Tooltip>
            )}
        </Div>
        </React.Fragment>
    );
};

export default ActiveConversationFooter;
