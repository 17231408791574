import React, { useContext, useState, useEffect }  from 'react';
import {useNavigate} from "react-router-dom";
import jwtAuthAxios from "../../../services/auth/jwtAuth";
import {Grid, Typography, Tabs, Tab, Box, TextField, Button} from "@mui/material";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import Div from "@jumbo/shared/Div";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import {useTheme} from "@mui/material";

import CurrentPlan from "../settingsProfile/CurrentPlan";
import ProjectCounter from "../settingsProfile/ProjectCounter";
import ResponseCounter from "../settingsProfile/ResponseCounter";
import MissionCard from "../settingsProfile/MissionCard"

import { UserContext } from '../../../contexts/DataContext';

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Div sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Div>
            )}
        </div>
    );
}

// Consider moving to a higher level settings class and then having subclasses. We can save on requests

const SettingsSecurity = () => {
    const navigate = useNavigate();
    const { user } = useContext(UserContext);

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    const tabIndex = 2
    
    const [projectCount, setProjectCount] = React.useState(user ? user.statistics.project_count : " ");
    const [responseCount, setResponseCount] = React.useState(user ? user.statistics.response_count : " ");
    const [activeSubscription, setActiveSubscription] = useState(user ? user.subscription : {})

    const [password, setPassword] = React.useState();
    const [passwordConfirmation, setPasswordConfirmation] = React.useState();

    const Swal = useSwalWrapper();
    const theme = useTheme();

    useEffect(() => {
        document.title = 'Security - Rdy';
      }, []);

    useEffect(() => {
        if(user) {
            setProjectCount(user.statistics.project_count)
            setResponseCount(user.statistics.response_count)
            setActiveSubscription(user.subscription)
        }
    }, [user])


    const selectProfile = () => {
        navigate("/settings")
    }

    const selectPlans = () => {
        navigate("/settings/plans")
    }

    const sweetAlerts = (variant, inputText) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: false,
            didOpen: toast => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
        });
        Toast.fire({
            icon: variant,
            title: inputText,
            background: theme.palette.background.paper,
        });
    };

    const formSubmit = () => {
        // TODO: Add better validations
        if(password.length < 8) {
            sweetAlerts('error', 'Password must be at least 8 characters');
        } else if(password !== passwordConfirmation) {
            sweetAlerts('error', 'Paswords do not match')
        } else {
            // Good enough
            const payload = {
                user: {
                    password: password,
                    password_confirmation: passwordConfirmation
                }
            }

            jwtAuthAxios.put("/users/set_password", payload)
                .then(response => {
                    sweetAlerts('success', 'Password saved!');
                })
                .catch(error => {
                    sweetAlerts('error', 'An error occurred while saving!');
                })

        }
    }

    return (
        <Grid container spacing={3.75}>
            <Grid item lg={7} md={12} sm={12}>
                <JumboCardQuick>
                    <Div sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <Tabs value={tabIndex}>
                            <Tab label="Profile" onClick={selectProfile}/>
                            <Tab label="Plans" onClick={selectPlans}/> 
                            <Tab label="Security"/>
                        </Tabs>
                    </Div>
                    <TabPanel value={tabIndex} index={2}>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': {m: 1, width: '92%'},
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <Div>
                                <Typography variant={"h3"}>Personal</Typography>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="password"
                                            label="Password"
                                            type="password"
                                            value={password}
                                            onChange={(event) => setPassword(event.target.value)}
                                            inputProps={{ maxLength: 64 }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="password-confirmation"
                                            label="Password Confirmation"
                                            type="password"
                                            value={passwordConfirmation}
                                            onChange={(event) => setPasswordConfirmation(event.target.value)}
                                            inputProps={{ maxLength: 64 }}
                                        />
                                    </Grid>
                                </Grid>
                            </Div>
                        </Box>
                        <Div style={{ marginTop: "16px", display: "flex", justifyContent: "flex-end" }}>
                            <Button variant="contained" onClick={formSubmit}>Save</Button>
                        </Div>
                    </TabPanel>
                </JumboCardQuick>
            </Grid>
            <Grid item lg={5} md={12} sm={12}>
                <Grid item xs={12} sx={{mb: 3}}>
                    <Grid container spacing={3.75}>
                        <Grid item md={6} xs={12}>
                            <ProjectCounter count={projectCount} />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <ResponseCounter count={responseCount} />
                        </Grid>
                        <Grid item lg={12} md={6} xs={12}>
                            <CurrentPlan
                                activePlan={activeSubscription?.plan_name}
                            />
                        </Grid>
                        <Grid item lg={12} md={6} xs={12}>
                            <MissionCard />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default SettingsSecurity;