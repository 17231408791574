import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Typography, Button} from "@mui/material";
import JumboCardFeatured from "@jumbo/components/JumboCardFeatured";
import {ASSET_IMAGES} from "../../../utils/constants/paths";
import {getAssetPath} from "../../../utils/appHelpers";

const StartCard = ({activeSubscription, setShowPlansModal}) => {
    const navigate = useNavigate();

    function handleNewProject() {
        if(activeSubscription.plan_name === "Free" || activeSubscription.status === "overdue") {
            // User must be on a paid plan to create a new project
            setShowPlansModal(true)
        } else {
            navigate('/projects/new');
        }
    }

    return (
        <JumboCardFeatured
            tickSx={{bgcolor: 'warning.main'}}
            direction={"column"}
            textAlign={"center"}
            bgImage={getAssetPath(`${ASSET_IMAGES}/dashboard-card-project.png`)}
            height={"282px"}
        >
            <Typography variant={"h3"} color={"white"} mb={.5}>
                Not sure where to begin?
            </Typography>
            <Button variant={"contained"} color={"warning"} sx={{mb: .5, mt: .5}} onClick={handleNewProject}>Start a new project!</Button>
            <Typography variant={"h6"} color="white" mt={1} sx={{fontWeight: 300}}>
                Create a new project or check out our help center!
            </Typography>
        </JumboCardFeatured>
    );
};

export default StartCard;
