import React from 'react';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import CardIconText from "@jumbo/shared/CardIconText";

const ProjectCounter = ({count = "0"}) => {
    return (
        <CardIconText
            icon={<LightbulbOutlinedIcon fontSize={"large"}/>}
            title={String(count)}
            height={90}
            hideArrow={true}
            subTitle={"Projects"}
        />
    );
};

export default ProjectCounter;
