import React from 'react';
import ListItemButton from "@mui/material/ListItemButton";
import {ListItemAvatar, ListItemText, Typography} from "@mui/material";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import {useNavigate} from "react-router-dom";

const ConversationItem = ({conversationItem}) => {
    const navigate = useNavigate();

    const onListItemClick = () => {
        navigate(`/chats/conversation/${conversationItem?.project_id}`);
    }

    return (
        <List disablePadding>
            <ListItemButton component="li" onClick={onListItemClick}>
                <ListItemAvatar>
                    {conversationItem?.last_message?.message_type === 'complete' ? (
                    <Badge color={"success"} badgeContent={" "} variant={"dot"}>
                        <Avatar alt={conversationItem?.contact?.name} src={conversationItem?.contact?.name} variant="rounded"/>
                    </Badge>
                    ) : (
                        <Avatar alt={conversationItem?.contact?.name} src={conversationItem?.contact?.name} variant="rounded"/>
                    )}
                </ListItemAvatar>
                <ListItemText
                    primary={
                        <Typography component="div" variant="body1" sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}>
                            <Typography variant="h6" mb={.5}
                                        fontSize={15}>{conversationItem?.contact?.name}</Typography>
                        </Typography>
                    }
                    secondary={
                        <Typography
                            component="div"
                            variant="body1"
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Typography
                                noWrap
                                variant="body1"
                                color={"text.secondary"}
                                fontSize={13}
                            >{conversationItem?.last_message?.message}</Typography>
                        </Typography>
                    }
                />
            </ListItemButton>
            <Divider component="li"/>
        </List>
    );
};
/* Todo conversationItem prop define */
export default ConversationItem;
