import {ASSET_IMAGES} from "../../utils/constants/paths";
import {getAssetPath} from "../../utils/appHelpers";

export const helpArticles = [
    {
        id: 1,
        image: getAssetPath(`${ASSET_IMAGES}/help-center-document-analysis.jpg`, `440x392`),
        title: 'Document analysis',
        description: 'A brief on what happens when you upload your project document to Rdy.',
        url: '/help-center/document-analysis',
    },
    {
        id: 2,
        image: getAssetPath(`${ASSET_IMAGES}/help-center-understanding-results.jpg`, `440x392`),
        title: 'Understanding your results',
        description: 'So you\'ve reached the end of a project assessment. What next?',
        url: '/help-center/understanding-your-results',
    },
    {
        id: 3,
        image: getAssetPath(`${ASSET_IMAGES}/help-center-technology.jpg`, `440x392`),
        title: 'What kind of documents does Rdy use?',
        description: 'Ready to start a project? Read up on the types of documents that are accepted into our upload system.',
        url: '/help-center/what-kind-of-documents-does-rdy-use',
    },

];