import React from 'react';
import {alpha, ListItemAvatar, ListItemText, Typography} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import ListItemButton from "@mui/material/ListItemButton";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import {useNavigate} from 'react-router-dom';

const ArticleItme = ({item}) => {
    const {theme} = useJumboTheme();

    const navigate = useNavigate();

    const handleNavigate = () => {
        navigate(item.url);
    }

    return (
        <React.Fragment>
            <ListItemButton
                disableRipple
                alignItems="flex-start"
                sx={{
                    p: 3,
                    transition: 'all 0.2s',
                    borderBottom: 1,
                    borderBottomColor: 'divider',

                    [theme.breakpoints.down('md')]: {
                        flexWrap: 'wrap'
                    },
                    '&:hover': {
                        boxShadow: `0 3px 10px 0 ${alpha('#000', 0.2)}`,
                        transform: 'translateY(-4px)',
                        borderBottomColor: 'transparent',
                    }
                }}
                onClick={handleNavigate}
            >
                <ListItemAvatar
                    sx={{
                        width: 220,
                        mt: 0,
                        mr: 3,
                        [theme.breakpoints.down('md')]: {
                            width: '100%',
                            mr: 0,
                            mb: 3
                        }
                    }}
                >
                    <Avatar
                        src={item.image}
                        variant={"rounded"}
                        sx={{
                            width: '100%',
                            height: 106,
                            [theme.breakpoints.down('md')]: {
                                height: 120,
                            }
                        }}
                    />
                </ListItemAvatar>
                <ListItemText>
                    <Typography component={"div"}>
                        <Typography variant={"h4"}>{item.title}</Typography>
                        <Typography variant={"body1"} mb={2}>{item.description}</Typography>
                    </Typography>
                </ListItemText>
            </ListItemButton>
        </React.Fragment>
    );
};
/* Todo item prop define */
export default ArticleItme;
