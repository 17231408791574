import React from 'react';
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import {IconButton, Typography, useMediaQuery, Chip, Avatar} from "@mui/material";
import Div from "@jumbo/shared/Div";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import JumboDdMenu from "@jumbo/components/JumboDdMenu/JumboDdMenu";
import useChatApp from "../../hooks/useChatApp";
import {useNavigate, useParams} from "react-router-dom";

const ActiveConversationHeader = () => {
    const {activeConversation} = useChatApp();
    const navigate = useNavigate();
    const {theme} = useJumboTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const { project_id } = useParams();

    const handleCloseConversation = () => {
        navigate(`/chats`);
    }

    const handleMenuClick = (event) => {
        switch(event.slug) {
            case "view-results":
                navigate(`/projects/${project_id}/results`)
                break;
            case "upload-document":
                navigate(`/projects/${project_id}/upload`)
                break;
            case "help-docs":
                navigate("/help-center")
                break;
            case "introduction":
                navigate(`/projects/${project_id}/introduction`)
                break;
            default:
                break;
        }
    }

    const menuItems = [
        ...(activeConversation?.complete ? [{ title: "View Results", slug: "view-results" }] : []),
        ...((activeConversation?.document_attached || activeConversation?.complete) ? [] : [{ title: "Upload Document", slug: "upload-document" }]),
        { title: "Introduction", slug: "introduction" },
        { title: "Help Docs", slug: "help-docs" }
    ]


    if (!activeConversation)
        return null;
    return (
        <Div
            sx={{
                display: 'flex',
                minWidth: 0,
                alignItems: 'center',
                borderBottom: 1,
                borderBottomColor: 'divider',
                p: theme => theme.spacing(2, 3),
            }}
        >
            {
                md &&
                <IconButton aria-label="Previous" sx={{mr: 2}} onClick={handleCloseConversation}>
                    <ArrowBackIcon/>
                </IconButton>
            }
            <Div
                sx={{
                    display: 'flex',
                    minWidth: 0,
                    flex: 1,
                    mr: 2
                }}
            >
                <Avatar
                    alt={activeConversation?.contact?.name}
                    src={activeConversation?.contact?.name}
                    sx={{mr: 2}}
                    variant="rounded"
                />
                <Div>
                    <Typography variant={'h5'} mb={.5}>
                        {activeConversation?.contact?.name}
                        {activeConversation.overdue && <Chip variant={'outlined'} color={'error'} size={"small"} label={'Overdue'} sx={{ ml: 1, mt: '-4px', verticalAlign: 'middle'}}/>}
                    </Typography>
                    <Typography fontSize={"12px"} variant={"body1"} color={'text.secondary'}>
                            <CalendarTodayOutlinedIcon
                                sx={{
                                    verticalAlign: 'middle',
                                    fontSize: '1rem',
                                    mt: -.25,
                                    mr: 1
                                }}
                            />
                            Due on {activeConversation.due_at}
                    </Typography>
                </Div>
            </Div>
            <JumboDdMenu
                menuItems={menuItems}
                onClickCallback={handleMenuClick}
            />
        </Div>
    );
};

export default ActiveConversationHeader;
