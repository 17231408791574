import React, { useEffect } from 'react';
import {Typography} from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import {ASSET_IMAGES} from "../../../../utils/constants/paths";
import {getAssetPath} from "../../../../utils/appHelpers";
import {useNavigate} from "react-router-dom";

const DocumentAnalysis = () => {
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Document Analysis - Rdy';

        window.scrollTo(0, 0);
    }, [])

    const onGoPrivacy = (e) => {
        e.preventDefault()
        navigate('/help-center/your-data-your-privacy')
    }

    return (
        <React.Fragment>
            <JumboCardQuick
                title={
                    <Typography component={"h2"} variant="h1">
                        Document analysis
                    </Typography>
                }
                wrapperSx={{pt: 0}}
            >
                <Grid container spacing={3.75}>
                    <Grid item xs={12} md={4}>
                        <CardMedia
                            component="img"
                            sx={{borderRadius: 2}}
                            image={getAssetPath(`${ASSET_IMAGES}/help-center-document-analysis-main.jpg`, "640x820")}
                            alt="Document analysis"
                        />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Typography variant="h4" color="text.primary">Input</Typography>
                        <Typography variant="body1" color="text.secondary" mb={1}>
                            Although our document upload process is completely optional, we highly recommend it as it helps paint a more objective perspective for you of your current status in your project.
                        </Typography>
                        <Typography variant="body1" color="text.secondary" mb={1}>
                            We process your document both internally and through OpenAI's GPT-4 API to obtain an analysis across a multitude of data points that are fed into the Rdy system. This data is then interpretted and output for you at relevant points during your project conversation.
                        </Typography>
                        <Typography variant="body1" color="text.secondary" mb={1}>
                            If you have concerns about how your data is used, please refer to our <a href="/help-center/your-data-your-privacy" onClick={onGoPrivacy} style={{ textDecoration: 'none', color: '#3B70DB'}}>Your Data, Your Privacy</a> article.
                        </Typography>
                        <Typography variant="h4" color="text.primary">Output</Typography>
                        <Typography variant="body1" color="text.secondary" mb={1}>
                            The results of your analysis show up above the input section in the chat, which provides our Rdy Analysis suggested confidence value and any feedback we've identified during our review.
                        </Typography>
                        <Typography variant="body1" color="text.secondary" mb={1}>
                            Our purpose is to address our human tendencies to over or underestimate, which significantly complicates delivery. Let's be honest, we have a tendency to be biased, and having an outside perspective can be infinitely helpful in evaluating our own thoughts.
                        </Typography>
                    </Grid>
                </Grid>
            </JumboCardQuick>
        </React.Fragment>
    );
};

export default DocumentAnalysis;
