import React, { useEffect } from 'react';
import {Card, CardContent, IconButton, Typography} from "@mui/material";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import {Facebook, Google, Twitter} from "@mui/icons-material";
import Div from "@jumbo/shared/Div";
import {alpha} from "@mui/material/styles";
import {ASSET_IMAGES} from "../../../utils/constants/paths";
import {getAssetPath} from "../../../utils/appHelpers";
import * as yup from "yup";
import {Form, Formik} from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import LoadingButton from "@mui/lab/LoadingButton";
import authServices from "../../../services/auth-services";
import {useNavigate} from "react-router-dom";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";

import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import {useTheme} from "@mui/material";

import useJumboLayoutSidebar from "@jumbo/hooks/useJumboLayoutSidebar";

const validationSchema = yup.object({
    email: yup
        .string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required')
        .min(6, 'Email is too short')
        .max(64, 'Email is too long'),
    password: yup
        .string('Enter your password')
        .required('Password is required'),
});

const Login2 = ({disableSmLogin}) => {
    const {setAuthToken, setData} = useJumboAuth();
    const {setSidebarOptions} = useJumboLayoutSidebar();

    const navigate = useNavigate();

    const Swal = useSwalWrapper();
    const theme = useTheme();

    useEffect(() => {
        document.title = 'Login - Rdy';

        setSidebarOptions({
            width: 0,
            minWidth: 0
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const sweetAlerts = (variant, inputText) => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: false,
            didOpen: toast => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
        });
        Toast.fire({
            icon: variant,
            title: inputText,
            background: theme.palette.background.paper,
        });
    };

    const onSignIn = (email, password) => {
        authServices.signIn({email, password})
            .then((data) => {
                sweetAlerts('success', 'Login successful!');
                setAuthToken(data?.token);
                setData(data);
                navigate("/dashboard");
            })
            .catch((error) => {
                sweetAlerts('error', 'Invalid password!');
            });
    };

    const onForgotPassword = (e) => {
        e.preventDefault()
        navigate("/reset-password")
    }

    const onSignup = (e) => {
        e.preventDefault()
        navigate("/signup")
    }

    return (
        <Div sx={{
            width: 720,
            maxWidth: '100%',
            margin: 'auto',
            p: 4
        }}>
            <Card
                sx={{
                    display: 'flex',
                    minWidth: 0,
                    flexDirection: {xs: 'column', md: 'row'}
                }}
            >
                <CardContent
                    sx={{
                        flex: '0 1 300px',
                        position: 'relative',
                        background: `#0267a0 url(${getAssetPath(`${ASSET_IMAGES}/registration-login-gradient.jpg`, "640x428")}) no-repeat center`,
                        backgroundSize: 'cover',

                        '&::after': {
                            display: 'inline-block',
                            position: 'absolute',
                            content: `''`,
                            inset: 0,
                            backgroundColor: alpha('#f19749', .1)
                        }
                    }}
                >
                    <Div
                        sx={{
                            display: 'flex',
                            minWidth: 0,
                            flex: 1,
                            flexDirection: 'column',
                            color: 'common.white',
                            position: 'relative',
                            zIndex: 1,
                            height: '100%',
                            minHeight: {md: 220}
                        }}
                    >
                        <Div sx={{mb: 2}}>
                            <Typography variant={"h4"} sx={{ color: 'common.white', fontWeight: 300 }} mb={2} mt={2}>
                                Sign in to your Rdy account.
                            </Typography>
                            <Typography variant={"body1"} sx={{ fontWeight: 300 }}>
                                <Link
                                    href={"/reset-password"}
                                    onClick={onForgotPassword}
                                    color={"inherit"}
                                    underline={'none'}
                                >Forgot your password?
                                </Link>
                            </Typography>
                        </Div>

                        <Div sx={{mt: 'auto'}}>
                            <img src={`${ASSET_IMAGES}/logo-128.png`} alt="Rdy AI" height="36" width="36"/>
                        </Div>
                    </Div>
                </CardContent>
                <CardContent sx={{flex: 1, p: 4}}
                >
                    <Formik
                        validateOnChange={true}
                        initialValues={{
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(data, {setSubmitting}) => {
                            setSubmitting(true);
                            onSignIn(data.email, data.password);
                            setSubmitting(false);
                        }}
                    >
                        {({isSubmitting}) => (
                            <Form style={{textAlign: 'left'}} noValidate autoComplete='off'>
                                <Div sx={{mt: 1, mb: 1}}>
                                    <JumboTextField
                                        fullWidth
                                        name="email"
                                        label="Email"
                                        inputProps={{ maxLength: 64 }}
                                    />
                                </Div>
                                <Div sx={{mt: 1, mb: 2}}>
                                    <JumboTextField
                                        fullWidth
                                        name="password"
                                        label="Password"
                                        type="password"
                                        inputProps={{ maxLength: 64 }}
                                    />
                                </Div>
                                <LoadingButton
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    size="medium"
                                    sx={{mb: 3}}
                                    loading={isSubmitting}
                                >Sign In</LoadingButton>
                                {
                                    !disableSmLogin && (
                                        <React.Fragment>
                                            <Typography variant={"body1"} mb={2}>Or sign in with</Typography>
                                            <Stack direction="row" alignItems="center" spacing={1} mb={1}>
                                                <IconButton sx={{
                                                    bgcolor: '#385196',
                                                    color: 'common.white',
                                                    p: theme => theme.spacing(1.25),

                                                    '&:hover': {
                                                        backgroundColor: '#385196',
                                                    }
                                                }} aria-label="Facebook">
                                                    <Facebook fontSize="small"/>
                                                </IconButton>
                                                <IconButton sx={{
                                                    bgcolor: '#00a8ff',
                                                    color: 'common.white',
                                                    p: theme => theme.spacing(1.25),

                                                    '&:hover': {
                                                        backgroundColor: '#00a8ff',
                                                    }
                                                }} aria-label="Twitter">
                                                    <Twitter fontSize="small"/>
                                                </IconButton>
                                                <IconButton sx={{
                                                    bgcolor: '#23272b',
                                                    color: 'common.white',
                                                    p: theme => theme.spacing(1.25),

                                                    '&:hover': {
                                                        backgroundColor: '#23272b',
                                                    }
                                                }} aria-label="Twitter">
                                                    <Google fontSize="small"/>
                                                </IconButton>
                                            </Stack>
                                        </React.Fragment>
                                    )
                                }

                            </Form>
                        )}
                    </Formik>
                </CardContent>
            </Card>
            <Div sx={{
                width: '100%',
                maxWidth: '100%',
                margin: 'auto',
                textAlign: 'center',
                p: 4,
                pt: 2,
                pb: 0,
                fontSize: '16px'
            }}>
                New? <Link href={'/signup'} onClick={onSignup} underline="none">Create an account</Link>
            </Div>
        </Div>
    );
};

export default Login2;
